
import axios, { AxiosResponse } from "axios";
import useAuthHeaders from "hooks/useAuthHeaders";
import useSignOut from 'react-auth-kit/hooks/useSignOut';
import { useMutation } from "react-query";
import { LoginParam, LoginResponse } from "types/login";

const useLogin = () => {
  const signOut = useSignOut();
  const headers = useAuthHeaders();

  return useMutation<AxiosResponse<LoginResponse>, unknown, LoginParam>(async payload=> {
    try {
      return await axios.post(`${process.env.REACT_APP_API_ENDPOINT}/login`, JSON.stringify(payload), { headers });
    } catch (err) {
      console.error(err);
      if (err?.response?.status === 401) signOut();
    }
  })
}

export default useLogin;
