import axios, { AxiosResponse } from "axios";
import useAuthHeaders from "hooks/useAuthHeaders";
import useSignOut from 'react-auth-kit/hooks/useSignOut';
import { useMutation } from "react-query";
import { UploadResponse } from "types/upload";

type UploadParams = File[];

const useUploadFiles = () => {
  const signOut = useSignOut();
  const headers = useAuthHeaders();

  return useMutation<AxiosResponse<UploadResponse>, unknown, UploadParams>(async (payload: File[]) => {
    try {
      const formData = new FormData();
      payload.forEach((file, index) => {
        formData.append(`files[${index}]`, file);
      })

      return await axios({
        method: 'POST',
        url: `${process.env.REACT_APP_API_ENDPOINT}/upload`,
        data: formData,
        headers: {
          ...headers,
          'Content-Type': 'multipart/form-data',
        },
      })
      
    } catch (err) {
      console.error(err);
      if (err?.response?.status === 401) signOut();
    }
  })
}

export default useUploadFiles;
