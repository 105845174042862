import axios, { AxiosResponse } from "axios";
import useAuthHeaders from "hooks/useAuthHeaders";
import useSignOut from 'react-auth-kit/hooks/useSignOut';
import { useQuery } from "react-query";
import { UserDetailResponse } from "types/user-detail";
interface GetUserResponse extends AxiosResponse {
  data: UserDetailResponse;
}

const useUserDetail = ({ user_id }) => {
  const signOut = useSignOut();
  const headers = useAuthHeaders();

  return useQuery<unknown, unknown, GetUserResponse>({
    queryKey: ['get-user-detail', user_id],
    queryFn: async () => {
      try {

        if (user_id === undefined) {
          return null;
        }

        return await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/user/${user_id}`, { headers })
      } catch (err) {
        console.error(err);
        if (err?.response?.status === 401) signOut();
      }
    },
  });
}

export default useUserDetail;