import axios, { AxiosResponse } from "axios";
import useAuthHeaders from "hooks/useAuthHeaders";
import useSignOut from 'react-auth-kit/hooks/useSignOut';
import { useQuery } from "react-query";
import { UserManagementAllResponse } from "types/user-management-all";

interface GetAllUserManagementResponse extends AxiosResponse {
    data: UserManagementAllResponse
}

const useAllUserManagement = () => {
    const signOut = useSignOut();
    const headers = useAuthHeaders();

    return useQuery<unknown, unknown, GetAllUserManagementResponse>({
        queryKey: ['get-all-user-management'],
        queryFn: async () => {

            try {
                return await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/user-management`, { headers })
            } catch (err) {
                console.error(err);
                if (err?.response?.status === 401) signOut();
            }
        },
    })
}

export default useAllUserManagement;
