import { Navigate } from 'react-router-dom';
import useAppContext from 'context/AppContext';
import ForbiddenPage from 'pages/ForbiddenPage';
import PageLoader from 'components/PageLoader';

const permissions = {
    patient: ['2', '3', '4', '5', '8'],
    queue: ['2', '3', '4', '8'],
    medicalRecord: ['3'],
    stock: ['2', '3', '6', '7', '8'],
    pharmacy: ['2', '3', '6'],
    document: ['2', '3', '4', '5', '6', '8'],
    report: ['2', '3', '7'],
    cashier: ['8']
};

const userHasPermission = (feature, userRole) => {
    return permissions[feature]?.includes(userRole);
};

const ProtectedRoute = ({ feature, children }) => {
    const { userDetail, loadingUserDetail } = useAppContext();

    if (loadingUserDetail) {
        return <PageLoader />;
    }

    if (!userDetail) {
        return <Navigate to="/login" />;
    }

    if (userDetail.is_admin === '1') {
        return children;
    }

    if (userHasPermission(feature, userDetail.type)) {
        return children;
    }

    return <ForbiddenPage />;
};

export default ProtectedRoute;
