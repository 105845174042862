import "moment/locale/id";
import { Route, Routes } from "react-router-dom";
import Loadable from 'react-loadable';
import PageLoader from "components/PageLoader";

const LazyCashier = Loadable({
    loader: () => import("pages/Cashier/index"),
    loading: () => <PageLoader />,
});

const LazyCashierDetail = Loadable({
    loader: () => import("pages/CashierDetail/index"),
    loading: () => <PageLoader />,
});


function CashierRoute() {
    return (
        <Routes>
            <Route path="/" element={<LazyCashier />} />
            <Route path="/new/:patient_id" element={<LazyCashierDetail />} />
            <Route path="/detail/:transaction_id" element={<LazyCashierDetail />} />
        </Routes>
    );
}

export default CashierRoute;
