import Modal from "components/Modal";
import ModalBody from "components/ModalBody";
import ModalFooter from "components/ModalFooter";
import ModalHeader from "components/ModalHeader";
import { Button } from '@material-tailwind/react';
import DataDisplay from 'components/DataDisplay';
import { useDetailUserManagement } from "api/user-management";
import { OPTIONS_GENDER_USER, OPTIONS_POLYCLINIC_UNIT, OPTIONS_USER_TYPE } from "constants/options";

interface ModalViewUserProps {
    isOpen: boolean;
    onClose: () => void;
    user_id: string;
}

const ModalViewUser = ({ isOpen, onClose, user_id }: ModalViewUserProps) => {
    const { data, isFetching } = useDetailUserManagement({ user_id });

    const user = data?.data?.data?.user;

    return (
        <Modal open={isOpen} onClose={onClose}>
            <ModalHeader>Detail User</ModalHeader>
            <ModalBody>
                {isFetching ? (
                    <div>Loading...</div>
                ) : (
                    user && (
                        <DataDisplay
                            items={[
                                { key: "Nama Lengkap", value: user.full_name },
                                { key: "Email", value: user.email },
                                {
                                    key: "Nomor Telepon",
                                    value: user.phone_number,
                                },
                                { key: "Jenis Kelamin", value: OPTIONS_GENDER_USER.find((g) => g.key === Number(user.gender))?.label },
                                { key: "Tanggal Lahir", value: user.birth_date },
                                { key: "Poli Klinik", value: OPTIONS_POLYCLINIC_UNIT.find((p) => p.key === Number(user.poly_clinic))?.label },
                                { key: "Role", value: OPTIONS_USER_TYPE.find((u) => u.key === Number(user.type))?.label },
                                { key: "Status", value: user.status === '1' ? 'Aktif' : 'Tidak Aktif' },
                            ]}
                        />
                    )
                )}
            </ModalBody>
            <ModalFooter>
                <Button onClick={onClose}>Tutup</Button>
            </ModalFooter>
        </Modal>
    );
};

export default ModalViewUser;
