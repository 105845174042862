import axios, { AxiosResponse } from "axios";
import useAuthHeaders from "hooks/useAuthHeaders";
import useSignOut from 'react-auth-kit/hooks/useSignOut';
import { useQuery } from "react-query";
import { DashboardDiseaseResponse } from "types/dashboard-disease";

interface GetDashboardDiseaseResponse extends AxiosResponse {
  data: DashboardDiseaseResponse;
}

const useDashboardDisease = () => {
  const signOut = useSignOut();
  const headers = useAuthHeaders();

  return useQuery<unknown, unknown, GetDashboardDiseaseResponse>({
    queryKey: ['get-dashboard-disease'],
    queryFn: async () => {
      try {
        return await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/dashboard/disease`, { headers })
      } catch (err) {
        console.error(err);
        if (err?.response?.status === 401) signOut();
      }
    },
  });
}

export default useDashboardDisease;