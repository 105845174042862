import "moment/locale/id";
import { Route, Routes } from "react-router-dom";
import Loadable from 'react-loadable';
import PageLoader from "components/PageLoader";

const LazyQueue = Loadable({
    loader: () => import("pages/Queue/index"),
    loading: () => <PageLoader />,
});

const LazyQueueForm = Loadable({
    loader: () => import("pages/QueueForm/index"),
    loading: () => <PageLoader />,
});

const LazyQueueAssessmentForm = Loadable({
    loader: () => import("pages/QueueAssessmentForm/index"),
    loading: () => <PageLoader />,
});

function QueueRoute() {
    return (
        <Routes>
            <Route path="/" element={<LazyQueue />} />
            <Route path="/new/:patient_id" element={<LazyQueueForm type="new" />} />
            <Route path="/assessment/:queue_id" element={<LazyQueueAssessmentForm type="assessment" />} />
        </Routes>
    );
}

export default QueueRoute;
