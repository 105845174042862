import { Box, CircularProgress, Typography } from "@mui/material";
import { useDashboardDisease } from "api/dashboard";
import EmptyData from "components/EmptyData";

const TopDisease = () => {
  const { data, isFetching } = useDashboardDisease();

  const disease = data?.data?.data?.disease || [];

  return (
    <Box className="space-y-2 p-4 bg-white rounded-lg">
      <div className="mb-4">
        <Typography variant="h6" >
          Penyakit Populer
        </Typography>
        <Typography variant="subtitle2" color="GrayText">
          Menampilkan penyakit terbanyak dalam 30 hari terakhir
        </Typography>
      </div>
      <div className=" divide-y">
        {isFetching && (
          <div className="flex justify-center">
            <CircularProgress />
          </div>
        )}
        {!isFetching && disease.length === 0 && (
          <EmptyData />
        )}
        {disease.map((item, index) => (
          <div
            key={item.icd10_id}
            className="flex justify-between gap-3 py-2 items-baseline"
          >
            <Typography variant="body2" className="w-[20px] shrink-0">{`${index + 1
              }.`}</Typography>
            <Typography variant="body2" className="grow">{item.label}</Typography>
            <Typography variant="body2" className=" text-red-400">
              {item.count}
            </Typography>
          </div>
        ))}
      </div>
    </Box>
  );
};

export default TopDisease;
