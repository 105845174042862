import { CustomFlowbiteTheme } from "flowbite-react";

export const materialTheme = {
  typography: {
    defaultProps: {
      color: "black"
    },
    styles: {
      variants: {
        paragraph: {
          fontFamily: "font-body",
        },
        lead: {
          fontFamily: "font-body",
        },
        small: {
          fontFamily: "font-body",
        },
        h1: {
          fontFamily: "font-sans",
          fontWeight: "font-bold",
          letterSpacing: "tracking-tight",
        },
        h2: {
          fontFamily: "font-sans",
          fontWeight: "font-bold",
          letterSpacing: "tracking-tight",
        },
        h3: {
          fontFamily: "font-sans",
          fontWeight: "font-bold",
          letterSpacing: "tracking-tight",
        },
        h4: {
          fontFamily: "font-sans",
          fontWeight: "font-bold",
          letterSpacing: "tracking-tight",
        },
        h5: {
          fontFamily: "font-sans",
          fontWeight: "font-bold",
          letterSpacing: "tracking-tight",
        },
        h6: {
          fontFamily: "font-sans",
          fontWeight: "font-bold",
          letterSpacing: "tracking-tight",
        },
      }
    }
  },
  button: {
    defaultProps: {
      color: "light-blue",
    },
    styles: {
      base: {
        initial: {
          textTransform: "none",
        }
      }
    }
  },
  list: {
    styles: {
      base: {
        list: {
          gap: "gap-1",
          minWidth: "min-w-[0]",
          color: "text-gray-700",
          fontFamily: "font-body",
        },
        item: {
          initial: {
            bg: "hover:bg-gray-200 hover:bg-opacity-80",
            color: "text-gray-700 hover:text-gray-900",
            gap: "gap-2",
          },
          selected: {
            bg: "bg-blue-50 hover:bg-blue-100",
            color: "text-blue-500 font-bold hover:text-blue-500",
          },
        },
        itemPrefix: {
          marginRight: "mr-0"
        },
      },
    },
  },
  breadcrumbs: {
    defaultProps: {
      separator: "/",
    },
    styles: {
      base: {
        list: {
          bg: "bg-transparent",
          py: "",
          px: "pr-2",
        },
        item: {
          initial: {
            fontSize: "text-xs",
            color: "text-gray-600",
            hover: "hover:text-gray-900",
          }
        }
      },
    },
  },
  card: {
    styles: {
      base: {
        initial: {
          borderRadius: 'rounded-lg'
        }
      }
    }
  }
}

export const flowbiteTheme: CustomFlowbiteTheme = {
  button: {
    color: {
      primary: "bg-sky-700",
    },
  },
  helperText: {
    root: {
      base: "mt-1 text-xs text-gray-600 dark:text-gray-500",
    },
  },
  textInput: {
    addon:
      "inline-flex order-2 items-center justify-center rounded-r-md font-bold border border-l-0 border-gray-300 bg-gray-100 min-w-[80px] px-3 text-sm text-gray-600 dark:border-gray-600 dark:bg-gray-600 dark:text-gray-400",
    field: {
      input: {
        withAddon: {
          on: "rounded-l-md",
        },
        withShadow: {
          on: "true",
        },
      },
    },
  },
  toggleSwitch: {
    toggle: {
      base: "toggle-bg h-6 w-11 rounded-full border group-focus:ring-4 group-focus:ring-sky-500/25",
    },
  },
  table: {
    body: {
      cell: {
        base: "group-first/body:group-first/row:first:rounded-tl-lg group-first/body:group-first/row:last:rounded-tr-lg group-last/body:group-last/row:first:rounded-bl-lg group-last/body:group-last/row:last:rounded-br-lg p-2 align-top",
      },
    },
    head: {
      cell: {
        base: "group-first/head:first:rounded-tl-lg group-first/head:last:rounded-tr-lg bg-gray-50 dark:bg-gray-700 px-3 py-3",
      },
    },
  },
  dropdown: {
    content: "bg-sky-500",
  },
  sidebar: {
    root: {
      base: "bg-transparent h-full",
      inner: "h-full overflow-y-auto overflow-x-hidden rounded py-4 px-3",
    },
    collapse: {
      label: {
        base: "ml-3 flex-1 whitespace-nowrap text-left hover:font-bold",
      },
    },
    item: {
      content: {
        base: "px-3 flex-1 whitespace-nowrap hover:font-bold",
      },
      active:
        "bg-mm-navy-1000 text-white dark:bg-mm-navy-700 hover:bg-mm-navy-1000 hover:text-white",
      icon: {
        base: "h-5 w-5 flex-shrink-0 text-gray-500 transition duration-75 group-hover:text-gray-900 dark:text-gray-400 dark:group-hover:text-white",
        active: "text-white",
      },
    },
  },
};