import { useRef } from "react";
import { FileInput, Label } from "flowbite-react";
import { HiX } from "react-icons/hi";
import { v4 as uuid } from "uuid";
import { CiImageOff } from "react-icons/ci";
import useToaster from "context/ToasterContext";
import { ClinicLogoAttachmentType } from "types/clinic-logo-attachment-type";

interface Props {
  title: string;
  value: ClinicLogoAttachmentType;
  onChange: (value: ClinicLogoAttachmentType) => void;
}

const AttachmentLogoClinic = ({ title, value, onChange }: Props) => {
  const toaster = useToaster();
  const inputRef = useRef(null);

  const handleReceiveFile = (e) => {
    const file = e.target.files[0];

    if (!file) return;

    if (file.size > 1024 * 1024 * 5) {
      toaster.open({
        title: 'Oops..',
        message: 'Maksimal 5MB',
        variant: 'error',
      });
      return;
    }

    const newValue = { id: uuid(), url: '', file };
    onChange(newValue);
  };

  const handleDeleteClick = () => {
    onChange(null);
  };

  const getPreview = (file: File) => {
    if (!file) return;
    return URL.createObjectURL(file);
  }

  return (
    <>
      <Label htmlFor="logo" value={title} />
      <div className="divide-y">
        {value?.file || value?.url ? (
          <div className="relative mt-2 h-28 w-28 border rounded-lg">
            {value.url || value.file ? (
              <img
                src={value.url || getPreview(value.file)}
                alt="logo"
                className="p-2 object-contain h-full w-full"
              />
            ) : (
              <div className="flex items-center justify-center h-full w-full text-gray-500">
                <CiImageOff className="w-12 h-12 mx-auto" />
              </div>
            )}
            <button
              className="absolute top-1 right-2 bg-red-500 text-white rounded-full p-1"
              onClick={handleDeleteClick}
            >
              <HiX />
            </button>
          </div>
        ) : (
          <div className="w-full">
            <div className="mt-2 mb-2 w-28 h-28 border rounded-lg flex items-center justify-center">
              <div className="text-center">
                <CiImageOff className="w-12 h-12 mx-auto" />
                <p className="text-xs font-semibold">Tidak ada gambar</p>
              </div>
            </div>
            <FileInput id="file-upload" accept="image/*" onChange={handleReceiveFile} ref={inputRef} />
          </div>
        )}
      </div>
    </>
  );
};

export default AttachmentLogoClinic;
