const Tabs = ({ tabs, activeTab, onTabChange }) => {

    return (
        <>
            <div className="flex flex-wrap">
                <div className="w-1/4">
                    <ul className="flex mb-0 list-none flex-row pt-3 pb-4" role="tablist">
                        {tabs.map((tab) => (
                            <li className="-mb-px text-center" key={tab.key}>
                                <button
                                    type="button"
                                    className={
                                        `text-md px-5 py-3 shadow-none rounded-none text-black bg-transparent ${activeTab === tab.key
                                            ? `border-b-2 border-gray-900 bg-blue-300`
                                            : `border-b border-blue-gray-50`
                                        }`
                                    }
                                    onClick={(e) => {
                                        e.preventDefault();
                                        onTabChange(tab.key);
                                    }}
                                    role="tablist"
                                >
                                    {tab.title}
                                </button>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </>
    );
};

export default Tabs;
