interface AvatarProps {
    name: string;
}

const AvatarUser = ({ name }: AvatarProps) => {
    const getInitials = (name: string) => {
        const namesArray = name.split(' ');
        const initials = namesArray.slice(0, 2).map(name => name.charAt(0).toUpperCase()).join('');
        return initials;
    };

    return (
        <div
            className="w-10 h-10 rounded-full flex items-center justify-center text-white bg-blue-400 font-bold"
        >
            {getInitials(name)}
        </div>
    );
};

export default AvatarUser;
