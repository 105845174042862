import axios from "axios";
import useAuthHeaders from "hooks/useAuthHeaders";
import useSignOut from 'react-auth-kit/hooks/useSignOut';
import { useMutation } from "react-query";

type Props = {
  filter: Record<string, string>;
}

const useStockRebalancing = () => {
  const signOut = useSignOut();
  const headers = useAuthHeaders();

  return useMutation(async ({ filter }: Props) => {
    const query = new URLSearchParams(filter).toString();
    try {
      return await axios.post(`${process.env.REACT_APP_API_ENDPOINT}/stock-card/rebalancing?${query}`, {}, { headers });
    } catch (err) {
      console.error(err);
      if (err?.response?.status === 401) signOut();
    }
  })
}

export default useStockRebalancing;
