import {
  TbReportMedical,
  TbBox,
  TbUserHeart,
  TbLayoutDashboard,
  TbLogout,
  TbHeadset,
  TbSettings,
  TbFile,
  TbReportAnalytics,
  TbBusinessplan,
  TbClockUp,
  TbCash,
} from "react-icons/tb";

import { GiMedicalDrip, GiMedicines } from "react-icons/gi";

export const MAIN_MENU = {
  title: "MENU",
  items: [
    { text: "Dashboard", icon: TbLayoutDashboard, url: "/" },
    { text: "Pasien", icon: TbUserHeart, url: "/patient" },
    { text: "Antrian", icon: TbClockUp, url: "/queue" },
    { text: "Rekam Medis", icon: TbReportMedical, url: "/medical-record" },
    { text: "Farmasi", icon: GiMedicines, url: "/pharmacy" },
    { text: "Kasir", icon: TbCash, url: "/cashier" },
    {
      text: "Stok",
      icon: TbBox,
      child: [
        { text: "Data Stok", url: "/stock" },
        { text: "Data Pembelian", url: "/stock/purchase" },
        { text: "Data Stok Opname", url: "/stock/opname" },
        { text: "Data Supplier", url: "/stock/supplier" },
      ],
    },
    { text: "Tindakan Medis", icon: GiMedicalDrip, url: "/medical-treatment" },
    {
      text: "Layanan Dokumen",
      icon: TbFile,
      child: [
        { text: "Data Pasien", url: "/document/patient" },
        { text: "Resume Medis", url: "/document/medical-resume" },
        { text: "Surat Sehat", url: "/document/health-letter" },
        { text: "Surat Sakit", url: "/document/sick-letter" },
        { text: "Surat Rujukan", url: "/document/medical-referral" },
        { text: "Invoice Rekam Medis", url: "/document/billing" },
      ],
    },
    { text: "Laporan", icon: TbReportAnalytics, url: "/report" },
  ],
};

export const EXTRAS_MENU = {
  title: "LAINNYA",
  items: [
    { text: "Pengaturan", icon: TbSettings, url: "/settings" },
    { text: "Langganan", icon: TbBusinessplan, url: "/subscription" },
    { text: "Bantuan", icon: TbHeadset, url: "/help" },
    { text: "Logout", icon: TbLogout, url: "/logout" },
  ],
};