import { Transition } from "@headlessui/react";
import { Fragment, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";

import { Button, Card, CircularProgress, IconButton, InputAdornment, Link, TextField, Typography } from "@mui/material";
import useReset from "api/auth/useReset";
import LayoutPlain from "components/LayoutPlain";
import useToaster from "context/ToasterContext";
import { FaArrowCircleLeft } from "react-icons/fa";
import { HiOutlineEye, HiOutlineEyeOff } from "react-icons/hi";
import { ResetPasswordParam } from "types/reset-password";

const ResetPassword = () => {
  const reset = useReset();
  const { open: openToaster } = useToaster();
  const navigate = useNavigate();
  const location = useLocation();
  const source = new URLSearchParams(location.search).get("activation-code");

  const [show, setShow] = useState(false);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [isConfirmPasswordVisible, setIsConfirmPasswordVisible] = useState(false);

  const { register, handleSubmit, formState: { errors }, watch } = useForm<ResetPasswordParam>()

  const onSubmit = (val: ResetPasswordParam) => {
    reset.mutate(val)
  }

  useEffect(() => {
    if (reset.isSuccess) {
      if (reset.data?.data?.success) {

        openToaster({
          title: "Reset Password Berhasil",
          message: reset.data?.data?.message || "",
          variant: "success",
          autoClose: true,
        });

        navigate("/login");
      } else {
        openToaster({
          title: "Reset Password Gagal",
          message: reset.data?.data?.message || "",
          variant: "error",
        });
      }
    }
  }, [reset, navigate, openToaster]);

  useEffect(() => {
    setTimeout(() => {
      setShow(true);
    }, 500);
  }, []);

  return (
    <LayoutPlain>
      <Transition appear show={show} as={Fragment}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0 translate-y-5"
          enterTo="opacity-100 translate-y-0"
        >
          <Card>
            <div className="max-w-4xl flex">
              {/* left section */}
              <div className="hidden w-full bg-gradient-to-b from-light-blue-800 to-blue-900 px-8 pb-12 pt-8 sm:block">
                <div className="align-center flex h-full flex-col justify-center gap-4">
                  <div className="flex grow flex-col items-center justify-center px-6 py-8">
                    <img
                      className="m-0 mx-auto"
                      src="/img_login.svg"
                      alt="Login"
                    />
                  </div>
                  <Typography variant="h3" color="white" className="!leading-[0.9em]">
                    Partner
                    <br />
                    sehat
                    <br />
                    bersama
                  </Typography>
                </div>
              </div>

              {/* right section */}
              <div className="flex w-full shrink-0 flex-col items-center justify-center space-y-4 p-4 py-8 sm:w-1/2 sm:p-16">
                <div className="w-full space-y-4 md:space-y-6">
                  <Typography variant="h4">
                    Pulihkan Password
                  </Typography>
                  <form
                    className="space-y-4 "
                    onSubmit={handleSubmit(onSubmit)}
                  >
                    <TextField
                      label="Password"
                      placeholder="Password"
                      type={isPasswordVisible ? "text" : "password"}
                      fullWidth
                      error={!!errors.new_password}
                      helperText={errors.new_password?.message}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={() => setIsPasswordVisible(!isPasswordVisible)}
                              onMouseDown={(e) => e.preventDefault()}
                              edge="end"
                            >
                              {isPasswordVisible ? <HiOutlineEyeOff /> : <HiOutlineEye />}
                            </IconButton>
                          </InputAdornment>
                        )
                      }}
                      {...register("new_password", {
                        validate: (value) => {
                          if (!value) {
                            return "Password tidak boleh kosong";
                          }
                          return true;
                        }
                      })}
                    />

                    <TextField
                      fullWidth
                      label="Konfirmasi Password"
                      placeholder="Konfirmasi Password"
                      type={isConfirmPasswordVisible ? "text" : "password"}
                      error={!!errors?.new_password2}
                      helperText={errors?.new_password2?.message}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={() => setIsConfirmPasswordVisible(!isConfirmPasswordVisible)}
                              onMouseDown={(e) => e.preventDefault()}
                              edge="end"
                            >
                              {isConfirmPasswordVisible ? <HiOutlineEyeOff /> : <HiOutlineEye />}
                            </IconButton>
                          </InputAdornment>
                        )
                      }}
                      {...register("new_password2", {
                        validate: (val: string) => {
                          if (val !== watch("new_password")) {
                            return "Password tidak cocok";
                          }
                          return true;
                        },
                      })}
                    />
                    <TextField
                      className="!hidden"
                      placeholder="Kode Aktivasi"
                      type="password"
                      value={source}
                      {...register("activation_code")}
                    />

                    <div className="pt-4">
                      <Button variant="contained" size="medium" type="submit" fullWidth>
                        {reset.isLoading ? <CircularProgress /> : "Pulihkan"}
                      </Button>
                    </div>
                  </form>
                </div>

                <div className="bottom-0">
                  <a href="/login">
                    <div className="flex items-center gap-1 group">
                      <IconButton size="small" className="group-hover:-translate-x-1">
                        <FaArrowCircleLeft height={14} />
                      </IconButton>
                      <Link href="/login">
                        Kembali ke Halaman Login
                      </Link>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </Card>
        </Transition.Child>
      </Transition>
    </LayoutPlain>
  );
};

export default ResetPassword;
