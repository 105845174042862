import "moment/locale/id";
import { Route, Routes } from "react-router-dom";
import Loadable from 'react-loadable';
import PageLoader from "components/PageLoader";

const LazyMedicalRecord = Loadable({
  loader: () => import("pages/MedicalRecord/index"),
  loading: () => <PageLoader />,
});

const LazyMedicalRecordForm = Loadable({
  loader: () => import("pages/MedicalRecordForm/index"),
  loading: () => <PageLoader />,
});

function MedicalRecordRoute() {
  return (
    <Routes>
      <Route path="/" element={<LazyMedicalRecord />} />
      <Route path="/new/:patient_id/:queue_id?" element={<LazyMedicalRecordForm type="new" />} />
      <Route path="/edit/:medical_record_id" element={<LazyMedicalRecordForm type="edit" />} />
    </Routes>
  );
}

export default MedicalRecordRoute;
