
import { Box, CircularProgress, FormControl, InputLabel, MenuItem, Select, Typography } from '@mui/material';
import { useDashboardIncome, useDashboardPurchase } from 'api/dashboard';
import {
  CategoryScale,
  ChartData,
  Chart as ChartJS,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  Title,
  Tooltip
} from 'chart.js';
import EmptyData from "components/EmptyData";
import toIDR from "helpers/toIDR";
import { useMemo } from 'react';
import { Line } from "react-chartjs-2";
import { useForm } from "react-hook-form";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const options = {
  responsive: true,
  plugins: {
    legend: {
      display: false,
      position: "top" as const,
    },
  },
  scales: {
    y: {
      ticks: {
        callback: (value: number) => {
          return `${toIDR(value / 1000)}k`;
        },
        font: {
          size: 10,
        }
      },
    },
    x: {
      grid: {
        display: false,
      },
      ticks: {
        font: {
          size: 11,
        }
      }
    },
  },
};


const ChartIncome = () => {
  const {
    register,
    watch,
  } = useForm<{ period: string }>({
    defaultValues: {
      period: 'daily'
    },
  });

  const period = watch('period');

  const { data: dataIncome, isFetching: loadingIncome } = useDashboardIncome();
  const incomeData = useMemo(() => dataIncome?.data?.data?.[`${period}_income`] || [], [dataIncome, period]);

  const { data: dataPurchase, isFetching: loadingPurchase } = useDashboardPurchase();
  const purchaseData = useMemo(() => dataPurchase?.data?.data?.[`${period}_purchase`] || [], [dataPurchase, period]);


  const chartIncomeData: ChartData<'line'> = useMemo(() => {
    if (!incomeData.length) return null;
    return {
      labels: incomeData.map((i) => {
        return period === 'daily'
          ? i.date
          : i.month
      }),
      datasets: [
        {
          label: "Pendapatan",
          data: incomeData.map((i) => Number(i.grand_total)),
          backgroundColor: "rgb(14 165 233 / 1)",
          'pointRadius': 8,
          'pointBorderWidth': 4,
        },
      ],
    }
  }, [incomeData, period]);

  const chartPurchaseData: ChartData<'line'> = useMemo(() => {
    if (!purchaseData.length) return null;
    return {
      labels: purchaseData.map((i) => {
        return period === 'daily'
          ? i.date
          : i.month
      }),
      datasets: [
        {
          label: "Pembelian",
          data: purchaseData.map((i) => Number(i.grand_total)),
          backgroundColor: "rgb(14 165 233 / 1)",
          'pointRadius': 8,
          'pointBorderWidth': 4,
        },
      ],
    }
  }, [purchaseData, period]);

  return (
    <>
      <div className="flex items-center justify-between gap-2 mb-2">
        <Typography variant="h5">
          Grafik Laporan
        </Typography>
        <FormControl sx={{ minWidth: 120 }} size="small">
          <InputLabel id="period-select">Periode</InputLabel>
          <Select
            labelId="period-select"
            label="Periode"
            defaultValue="daily"
            {...register('period')}
          >
            <MenuItem value="daily">Harian</MenuItem>
            <MenuItem value="monthly">Bulanan</MenuItem>
          </Select>
        </FormControl>
      </div>
      <div className='grid grid-cols-1 gap-4 lg:grid-cols-2'>
        <Box className="rounded-lg bg-white space-y-2 p-4">
          <div className="mb-4">
            <Typography variant="h6">
              Pembelian
            </Typography>
            <Typography variant="subtitle2" color="GrayText">
              {period === 'daily' ? `Menampilkan pembelian ${purchaseData.length} hari terakhir` : `Menampilkan pembelian ${purchaseData.length} bulan terakhir`}
            </Typography>
          </div>

          {loadingPurchase && (
            <div className="flex justify-center">
              <CircularProgress />
            </div>
          )}
          {!loadingPurchase && purchaseData.length > 0 && chartPurchaseData && (
            <Line options={options} data={chartPurchaseData} />
          )}
          {!loadingPurchase && purchaseData.length === 0 && (
            <EmptyData />
          )}
        </Box>
        <Box className="rounded-lg bg-white space-y-2 p-4">
          <div className="mb-4">
            <Typography variant="h6">
              Pendapatan
            </Typography>
            <Typography variant="subtitle2" color="GrayText" >
              {period === 'daily' ? `Menampilkan pendapatan ${purchaseData.length} hari terakhir` : `Menampilkan pendapatan ${purchaseData.length} bulan terakhir`}
            </Typography>
          </div>

          {loadingIncome && (
            <div className="flex justify-center">
              <CircularProgress />
            </div>
          )}
          {!loadingIncome && incomeData.length > 0 && chartIncomeData && (
            <Line options={options} data={chartIncomeData} />
          )}
          {!loadingIncome && incomeData.length === 0 && (
            <EmptyData />
          )}
        </Box>
      </div>
    </>
  );
};

export default ChartIncome;
