import { CircularProgress, Tooltip, Typography } from '@mui/material';
import { useDashboardSummary } from 'api/dashboard';
import React from 'react';
import { HiArrowRight } from 'react-icons/hi';
import { useNavigate } from 'react-router-dom';
import { STATS } from '../constants';

interface StatisticCardProps {
  title: string;
  description: string;
  value: number | string;
  color?: string;
  onClick: () => void;
}

const StatisticCard: React.FC<StatisticCardProps> = ({ title, description, value, color, onClick }) => {
  return (
    <Tooltip title={description} placement="bottom">
      <div className="bg-white rounded-lg cursor-pointer flex flex-col hover:scale-105 relative" onClick={onClick}>
        <div className="p-4 items-center gap-2 grow">
          <Typography variant="h3" className={`font-extrabold text-${color}-500`}>
            {value}
          </Typography>
          <Typography variant="caption" color="CaptionText" className='!font-bold'>
            {title}
          </Typography>
        </div>
        <div className="absolute top-4 right-4">
          <HiArrowRight className="text-gray-500 -rotate-45" />
        </div>
      </div>
    </Tooltip>
  );
};

const Statistics: React.FC = () => {
  const navigate = useNavigate();

  const { data, isFetching } = useDashboardSummary();
  const statsData = data?.data?.data;

  return (
    <div className="grid grid-cols-2 lg:grid-cols-4 gap-4">
      {isFetching && (
        <div className="flex justify-center col-span-4">
          <CircularProgress />
        </div>
      )}
      {!isFetching && (
        <>
          {STATS.map(({ key, url, ...rest }) => (
            <StatisticCard {...rest} value={statsData?.[key]?.count} onClick={() => navigate(url)} />
          ))}
        </>
      )}
    </div>
  );
};

export default Statistics;
