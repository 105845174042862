import axios, { AxiosResponse } from "axios";
import useAuthHeaders from "hooks/useAuthHeaders";
import useSignOut from 'react-auth-kit/hooks/useSignOut';
import { useQuery } from "react-query";
import { UserManagementDetailResponse } from "types/user-management-detail";

interface UserManagementDetailPayload {
    user_id?: string;
}

interface GetDetailUserManagementResponse extends AxiosResponse {
    data: UserManagementDetailResponse;
}

const useDetailUserManagement = ({ user_id }: UserManagementDetailPayload) => {
    const signOut = useSignOut();
    const headers = useAuthHeaders();

    return useQuery<unknown, unknown, GetDetailUserManagementResponse>({
        queryKey: ['get-user-management-detail', user_id],
        queryFn: async () => {
            try {
                return await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/user-management/${user_id}`, { headers });
            } catch (err) {
                console.error(err);
                if (err?.response?.status === 401) signOut();
                throw err;
            }
        },
        enabled: !!user_id,
    });
};

export default useDetailUserManagement;
