import { Fragment, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { Transition } from "@headlessui/react";
import { Button, Card, Typography } from "@mui/material";

import useActivate from "api/auth/useActivate";
import LayoutPlain from "components/LayoutPlain";

const Activation = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [show, setShow] = useState(false);
  const [activateMessage, setActivateMessage] = useState("");
  const [activateStatus, setActivateStatus] = useState(false);

  const { data } = useActivate({
    filter: Object.fromEntries(new URLSearchParams(location.search)),
  });

  useEffect(() => {
    if (data?.data.success === true) {
      setActivateMessage(data?.data?.message ?? "akun berhasil diaktifkan");
      setActivateStatus(true);
    } else {
      setActivateMessage(data?.data?.message ?? "akun gagal diaktifkan");
      setActivateStatus(false);
    }
  }, [data]);

  useEffect(() => {
    setTimeout(() => {
      setShow(true);
    }, 500);
  }, []);

  const handleButton = () => {
    if (activateStatus === true) {
      navigate("/login");
    } else {
      navigate("/register");
    }
  }

  return (
    <LayoutPlain>
      <Transition appear show={show} as={Fragment}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0 translate-y-5"
          enterTo="opacity-100 translate-y-0"
        >

          <div>
            <Card>
              <div className="min-w-[70vw] max-w-3xl space-y-4 p-8 sm:min-w-0">
                <img
                  className="m-8 mx-auto w-60"
                  src={activateStatus === true ? "/img_activation_success.svg" : "/img_activation_failed.svg"}
                  alt="Activate"
                />
                <Typography variant="h4" align="center">
                  {activateStatus === true ? "Aktivasi Berhasil" : "Aktivasi Gagal"}
                </Typography>
                <Typography variant="body1" align="center" className="max-w-sm">{activateMessage}</Typography>
                <Button type="submit" variant="contained" onClick={handleButton} fullWidth>
                  {(activateStatus === true) ? 'Login' : 'Registrasi'}
                </Button>
              </div>
            </Card>
          </div>
        </Transition.Child>
      </Transition>
    </LayoutPlain>
  );
};

export default Activation;
