import { useUserDetail } from "api/user";
import useAuthUser from 'react-auth-kit/hooks/useAuthUser';
import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import { UserDetailResponse } from "types/user-detail";
import useClinicDetail from "api/clinic/useDetailClinic";
import { ClinicDetailResponse } from "types/clinic-detail";
import { UserData } from "entities/model/user";

const MOBILE_BREAKPOINT = 640;

type UserType = UserDetailResponse["data"]["user"];
type ClinicType = ClinicDetailResponse["data"]["clinic"];
interface AppContextInterface {
  isMobile: boolean;
  userDetail: UserType;
  clinicDetail: ClinicType;
  loadingUserDetail: boolean;
}

export const AppContext = createContext({} as AppContextInterface);

export const AppProvider = ({ children }: { children: ReactNode }) => {
  const auth = useAuthUser<UserData>();
  const userId = auth?.userId;
  const clinicId = auth?.clinicId;

  const { data: user } = useUserDetail({ user_id: userId });
  const userData = user?.data?.data?.user;

  const { data: clinic } = useClinicDetail({ clinic_id: clinicId });
  const clinicData = clinic?.data?.data?.clinic;

  const [loadingUserDetail, setLoadingUserDetail] = useState<boolean>(true);
  const [userDetail, setUserDetail] = useState<UserType>(undefined);
  const [clinicDetail, setClinicDetail] = useState<ClinicType>(undefined);

  const [isMobile, setIsMobile] = useState(
    window.innerWidth <= MOBILE_BREAKPOINT
  );

  const handleResize = () => {
    setIsMobile(window.innerWidth <= MOBILE_BREAKPOINT);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  });

  useEffect(() => {
    if (userData && !userDetail) {
      setUserDetail(userData);
      setLoadingUserDetail(false);
    }
  }, [userData, userDetail]);

  useEffect(() => {
    if (clinicData && !clinicDetail) {
      setClinicDetail(clinicData);
    }
  }, [clinicData, clinicDetail]);

  return (
    <AppContext.Provider
      value={{
        isMobile,
        userDetail,
        clinicDetail,
        loadingUserDetail,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

const useAppContext = () => {
  return useContext(AppContext);
};

export default useAppContext;
