import { Carousel } from "@material-tailwind/react";
import { Alert, Box, Button, CardContent, CircularProgress, IconButton, Typography } from "@mui/material";
import { useDashboardInformation } from "api/dashboard";
import { HiChevronRight } from "react-icons/hi";

const Information = () => {

  const { data, isFetching } = useDashboardInformation();
  const informations = data?.data?.data?.ticker || [];

  const handleLinkClick = (url: string) => {
    window.location.href = url;
  };

  return (
    <Box className="rounded-lg bg-cover bg-bottom" style={{ backgroundImage: `url(/wave.png)`, backgroundPositionY: '92%' }}>
      <CardContent>
        <Typography variant="h6">Informasi Terbaru</Typography>
        {isFetching && (
          <div className="flex justify-center items-center pb-12">
            <CircularProgress />
          </div>
        )}
        {!isFetching && informations.length === 0 && (
          <div className="pb-12 pt-4">
            <Typography variant="subtitle2">
              Belum ada informasi terbaru untuk Anda hari ini. Apabila ada informasi terbaru, akan ditampilkan di sini. Terima kasih dan <b>selamat bekerja!</b>
            </Typography>
          </div>
        )}
        {!isFetching && informations.length > 0 && (
          <Carousel
            loop
            autoplay
            autoplayDelay={5000}
            navigation={({ setActiveIndex, activeIndex, length }) => (
              <div className="absolute bottom-1 left-2/4 flex -translate-x-2/4 gap-2">
                {new Array(length).fill("").map((_, i) => (
                  <span
                    key={i}
                    className={`block h-1 cursor-pointer rounded-2xl transition-all content-[''] ${activeIndex === i ? "w-8 bg-gray-500" : "w-4 bg-gray-500/50"
                      }`}
                    onClick={() => setActiveIndex(i)}
                  />
                ))}
              </div>
            )}
            prevArrow={() => null}
            nextArrow={({ handleNext }) => (
              <IconButton
                color="default"
                size="medium"
                onClick={handleNext}
                className="!absolute top-1/4 !right-1 -translate-y-2/4"
              >
                <HiChevronRight size={24} className="text-gray-500" />
              </IconButton>
            )}
          >
            {informations.map((item) => (
              <Alert
                color="info"
                variant="outlined"
                icon={false}
                className="!p-0 !pr-8 !mt-0 !border-none"
              >
                <div className="space-y-2 pb-2">
                  <Typography variant="subtitle2">
                    {item.description}
                  </Typography>
                  <Button variant="contained" size="small" color="secondary" onClick={() => handleLinkClick(item.cta_link)}>
                    {item.cta_title}
                  </Button>
                </div>
              </Alert>
            ))}
          </Carousel>
        )}
      </CardContent>
    </Box>
  )
}

export default Information;
