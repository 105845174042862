import { Typography } from "@material-tailwind/react";
import clsx from "clsx";

interface SectionProps extends React.HTMLProps<HTMLDivElement> {
  title?: string;
  actionElement?: React.ReactNode,
}

const FormSection = ({ title, actionElement, className, children }: SectionProps) => {
  return (
    <section
      className={clsx(
        "border-b pb-8 pt-10 last:border-b-0",
        className
      )}
    >
      <div className="flex justify-between items-center mb-6 empty:mb-0">
        {title && (
          <Typography as="h5" variant="h5">
            {title}
          </Typography>
        )}
        {actionElement}
      </div>
      {children}
    </section>
  );
};

export default FormSection;
