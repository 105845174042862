const getLastNYearList = (count: number) => {
  const currentYear = new Date().getFullYear();
  let result = [currentYear];

  for (let index = 1; index < count; index++) {
    result = [(currentYear - index), ...result];
  }

  return result;
}

export default getLastNYearList;
