import { useForm } from "react-hook-form";
import Modal from "components/Modal";
import SearchPasienItemDisplay from "./SearchPasienItemDisplay";
import { useNavigate } from "react-router-dom";
import { Spinner, TextInput } from "flowbite-react";
import { useDebounce } from "use-debounce";
import { useAllPatient } from "api/patient";
import { PatientTypeData } from "types/patient";
import EmptyData from "components/EmptyData";
import { Button, Typography } from "@material-tailwind/react";
import ModalHeader from "components/ModalHeader";
import ModalBody from "components/ModalBody";
import ModalFooter from "components/ModalFooter";

interface PasienType {
  id_card_number: string;
  patient_name: string;
  medical_record_number: string;
}

interface SearchPasienProps {
  open: boolean;
  onClose: () => void;
}

const SearchPasien = ({ open, onClose }: SearchPasienProps) => {
  const navigate = useNavigate();

  const { register, watch } = useForm<PasienType>();

  const [keywordName] = useDebounce(watch("patient_name"), 500);
  const [keywordMr] = useDebounce(watch("medical_record_number"), 500);
  const [keywordNik] = useDebounce(watch("id_card_number"), 500);

  const hasSearchQuery = Boolean(
    keywordName?.length || keywordMr?.length || keywordNik?.length
  );

  const query = new URLSearchParams({
    id_card_number: keywordNik,
    medical_record_number: keywordMr,
    patient_name: keywordName,
  }).toString();

  const { data, isFetching } = useAllPatient(query, { enabled: hasSearchQuery });

  const handlePasienClick = (item: PatientTypeData) => {
    navigate(`/medical-record/new/${item.patient_id}`);
    onClose();
  };

  const handleNewPasienClick = () => {
    navigate("/patient/new");
    onClose();
  }

  return (
    <Modal open={open} onClose={onClose}>
      <ModalHeader>Cari Pasien</ModalHeader>
      <ModalBody className="pb-4">
        <div className="space-y-4">
          <div className="grid grid-cols-2 gap-2">
            <TextInput
              type="search"
              placeholder="Nama"
              className="col-span-2"
              autoComplete="off"
              {...register("patient_name")}
            />
            <TextInput
              type="search"
              placeholder="No. Rekam Medis"
              className="col-span-1"
              autoComplete="off"
              {...register("medical_record_number")}
            />
            <TextInput
              type="search"
              placeholder="NIK"
              className="col-span-1"
              autoComplete="off"
              {...register("id_card_number")}
            />
          </div>
          <div className="max-h-[300px] overflow-y-auto rounded-lg border">
            {isFetching && <Spinner size="lg" className="mx-auto w-full" />}
            {!hasSearchQuery && <EmptyData>Ketik di kolom pencarian</EmptyData>}
            {hasSearchQuery &&
              !isFetching &&
              data?.data?.data?.patient?.length === 0 && (
                <EmptyData>Data tidak ditemukan</EmptyData>
              )}
            {hasSearchQuery &&
              data?.data?.data?.patient?.map((item, index) => (
                <SearchPasienItemDisplay
                  key={index}
                  item={item}
                  onClick={() => handlePasienClick(item)}
                />
              ))}
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <Typography className="text-sm text-gray-500">
          Tidak dapat menemukan pasien?
        </Typography>
        <Button
          type="button"
          variant="outlined"
          onClick={handleNewPasienClick}
        >
          Input Pasien Baru
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default SearchPasien;
