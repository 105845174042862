import { Transition } from "@headlessui/react";
import { Fragment, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import { Button, Card, CircularProgress, TextField, Typography } from "@mui/material";
import useForget from "api/auth/useForget";
import LayoutPlain from "components/LayoutPlain";
import useToaster from "context/ToasterContext";
import { ForgetParam } from "types/reset-password";

const ForgetPassword = () => {
  const { register, handleSubmit, formState: { errors } } = useForm<ForgetParam>();
  const forget = useForget();
  const { open: openToaster } = useToaster();
  const navigate = useNavigate();

  const [show, setShow] = useState(false);

  const onSubmit = async (val: ForgetParam) => {
    if (forget.isLoading) return;

    forget.mutate(val);
  };

  useEffect(() => {
    if (forget.isSuccess) {
      if (forget.data?.data?.success) {

        openToaster({
          title: "Berhasil",
          message: forget.data?.data?.message || "",
          variant: "success",
          autoClose: true,
        });

        navigate("/login");
      } else {
        openToaster({
          title: "Gagal",
          message: forget.data?.data?.message || "",
          variant: "error",
        });
      }
    }
  }, [forget, navigate, openToaster]);

  useEffect(() => {
    setTimeout(() => {
      setShow(true);
    }, 500);
  }, []);

  return (
    <LayoutPlain>
      <Transition appear show={show} as={Fragment}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0 translate-y-5"
          enterTo="opacity-100 translate-y-0"
        >
          <Card>
            <div className="max-w-4xl flex">
              {/* left section */}
              <div className="hidden w-full bg-gradient-to-b from-light-blue-800 to-blue-900 px-8 pb-12 pt-8 sm:block">
                <div className="align-center flex h-full flex-col justify-center gap-4">
                  <div className="flex grow flex-col items-center justify-center px-6 py-8">
                    <img
                      className="m-0 mx-auto"
                      src="/img_login.svg"
                      alt="Login"
                    />
                  </div>
                  <Typography variant="h3" color="white" className="!leading-[0.9em]">
                    Partner
                    <br />
                    sehat
                    <br />
                    bersama
                  </Typography>
                </div>
              </div>

              {/* right section */}
              <div className="flex w-full shrink-0 flex-col items-center justify-center space-y-4 p-4 py-8 sm:w-1/2 sm:p-8 lg:p-12">
                <div className="w-full space-y-4 md:space-y-6">
                  <Typography variant="h4">
                    Lupa Kata Sandi?
                  </Typography>
                  <Typography variant="body1">
                    Silakan masukkan alamat email yang terkait dengan akun Anda di bawah ini, dan kami akan mengirimkan instruksi tentang cara mengatur ulang kata sandi Anda.
                  </Typography>
                  <form
                    noValidate
                    className="space-y-4 "
                    onSubmit={handleSubmit(onSubmit)}
                  >
                    <TextField
                      label="Email"
                      placeholder="Email"
                      type="email"
                      fullWidth
                      autoFocus
                      error={!!errors.email}
                      helperText={errors.email?.message}
                      {...register("email", {
                        validate: (value) => {
                          if (!value) {
                            return "Email tidak boleh kosong";
                          } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
                            return "Email tidak valid";
                          }
                          return true;
                        }
                      })}
                    />
                    <Button variant="contained" size="medium" type="submit" fullWidth>
                      {forget.isLoading ? <CircularProgress /> : "Kirim Email"}
                    </Button>
                  </form>
                </div>
              </div>
            </div>
          </Card>
        </Transition.Child>
      </Transition>
    </LayoutPlain>
  );
};

export default ForgetPassword;