import "moment/locale/id";
import { Route, Routes } from "react-router-dom";
import Loadable from "react-loadable";
import PageLoader from "components/PageLoader";

const LazyStock = Loadable({
  loader: () => import("pages/Stock/index"),
  loading: () =><PageLoader />,
});

const LazyStockForm = Loadable({
  loader: () => import("pages/StockForm/index"),
  loading: () =><PageLoader />,
});

const LazyStockDetail = Loadable({
  loader: () => import("pages/StockDetail/index"),
  loading: () =><PageLoader />,
});

const LazyStockPurchase = Loadable({
  loader: () => import("pages/StockPurchase/index"),
  loading: () =><PageLoader />,
});

const LazyStockPurchaseForm = Loadable({
  loader: () => import("pages/StockPurchaseForm/index"),
  loading: () =><PageLoader />,
});

const LazyStockSupplier = Loadable({
  loader: () => import("pages/StockSupplier/index"),
  loading: () =><PageLoader />,
});

const LazyStockOpname = Loadable({
  loader: () => import("pages/StockOpname/index"),
  loading: () =><PageLoader />,
});

const LazyStockOpnameForm = Loadable({
  loader: () => import("pages/StockOpnameForm/index"),
  loading: () =><PageLoader />,
});

function StockRoute() {
  return (
    <Routes>
      <Route path="/" element={<LazyStock />} />
      <Route path="/new" element={<LazyStockForm type="new" />} />
      <Route path="/edit/:stock_id" element={<LazyStockForm type="edit" />} />
      <Route path="/detail" element={<LazyStockDetail />} />
      <Route path="/purchase" element={<LazyStockPurchase />} />
      <Route
        path="/purchase/new"
        element={<LazyStockPurchaseForm type="new" />}
      />
      <Route
        path="/purchase/edit/:purchase_id"
        element={<LazyStockPurchaseForm type="edit" />}
      />
      <Route path="/supplier" element={<LazyStockSupplier />} />
      <Route path="/opname" element={<LazyStockOpname />} />
      <Route path="/opname/new" element={<LazyStockOpnameForm type="new" />} />
      <Route
        path="/opname/view/:stock_opname_id"
        element={<LazyStockOpnameForm type="view" />}
      />
    </Routes>
  );
}

export default StockRoute;
