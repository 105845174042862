interface MenuAccess {
    role: string;
    menus: { name: string; access: boolean }[];
}

export const MENU_ACCESS_DATA: MenuAccess[] = [
    {
        role: '2',
        menus: [
            { name: 'Dashboard', access: true },
            { name: 'Pasien', access: true },
            { name: 'Antrian', access: true },
            { name: 'Rekam Medis', access: false },
            { name: 'Stok', access: true },
            { name: 'Kasir', access: false },
            { name: 'Farmasi', access: true },
            { name: 'Layanan Dokumen', access: true },
            { name: 'Laporan', access: true },
            { name: 'Pengaturan', access: true },
            { name: 'Langganan', access: false },
            { name: 'Ganti Password', access: true },
        ],
    },
    {
        role: '3',
        menus: [
            { name: 'Dashboard', access: true },
            { name: 'Pasien', access: true },
            { name: 'Antrian', access: true },
            { name: 'Rekam Medis', access: true },
            { name: 'Stok', access: true },
            { name: 'Kasir', access: false },
            { name: 'Farmasi', access: true },
            { name: 'Layanan Dokumen', access: true },
            { name: 'Laporan', access: true },
            { name: 'Pengaturan', access: true },
            { name: 'Langganan', access: false },
            { name: 'Ganti Password', access: true },
        ],
    },
    {
        role: '4',
        menus: [
            { name: 'Dashboard', access: true },
            { name: 'Pasien', access: true },
            { name: 'Antrian', access: true },
            { name: 'Rekam Medis', access: false },
            { name: 'Stok', access: false },
            { name: 'Layanan Dokumen', access: true },
            { name: 'Laporan', access: false },
            { name: 'Pengaturan', access: true },
            { name: 'Langganan', access: false },
            { name: 'Ganti Password', access: true },
        ],
    },
    {
        role: '5',
        menus: [
            { name: 'Dashboard', access: true },
            { name: 'Pasien', access: true },
            { name: 'Antrian', access: false },
            { name: 'Rekam Medis', access: false },
            { name: 'Stok', access: false },
            { name: 'Layanan Dokumen', access: true },
            { name: 'Laporan', access: false },
            { name: 'Pengaturan', access: true },
            { name: 'Langganan', access: false },
            { name: 'Ganti Password', access: true },
        ],
    },
    {
        role: '6',
        menus: [
            { name: 'Dashboard', access: true },
            { name: 'Pasien', access: false },
            { name: 'Antrian', access: false },
            { name: 'Rekam Medis', access: false },
            { name: 'Stok', access: true },
            { name: 'Kasir', access: false },
            { name: 'Farmasi', access: true },
            { name: 'Layanan Dokumen', access: true },
            { name: 'Laporan', access: false },
            { name: 'Pengaturan', access: true },
            { name: 'Langganan', access: false },
            { name: 'Ganti Password', access: true },
        ],
    },
    {
        role: '7',
        menus: [
            { name: 'Dashboard', access: true },
            { name: 'Pasien', access: false },
            { name: 'Antrian', access: false },
            { name: 'Rekam Medis', access: false },
            { name: 'Stok', access: true },
            { name: 'Kasir', access: false },
            { name: 'Farmasi', access: false },
            { name: 'Layanan Dokumen', access: false },
            { name: 'Laporan', access: true },
            { name: 'Pengaturan', access: true },
            { name: 'Langganan', access: false },
            { name: 'Ganti Password', access: true },
        ],
    },
    {
        role: '8',
        menus: [
            { name: 'Dashboard', access: true },
            { name: 'Pasien', access: true },
            { name: 'Antrian', access: true },
            { name: 'Rekam Medis', access: false },
            { name: 'Stok', access: true },
            { name: 'Kasir', access: true },
            { name: 'Farmasi', access: false },
            { name: 'Layanan Dokumen', access: true },
            { name: 'Laporan', access: false },
            { name: 'Pengaturan', access: true },
            { name: 'Langganan', access: false },
            { name: 'Ganti Password', access: true },
        ],
    },
];