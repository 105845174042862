import Modal from "components/Modal";
import ModalBody from "components/ModalBody";
import ModalFooter from "components/ModalFooter";
import ModalHeader from "components/ModalHeader";
import { Button, Typography } from '@material-tailwind/react';
import { useDetailUserManagement } from "api/user-management";
import { TextInput } from "flowbite-react";
import { HiEye, HiEyeOff } from "react-icons/hi";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { UserManagementChangePasswordType } from "types/user-management-change-password";

interface ModalChangePasswordProps {
    isOpen: boolean;
    onClose: () => void;
    user_id: string;
    onChangePassword: (val: UserManagementChangePasswordType) => void;
}

const ModalChangePassword = ({ isOpen, onClose, user_id, onChangePassword }: ModalChangePasswordProps) => {
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setshowConfirmPassword] = useState(false);

    const {
        register,
        watch,
        handleSubmit,
        formState: { errors },
    } = useForm<UserManagementChangePasswordType>();

    const toggleShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const toggleShowConfirmPassword = () => {
        setshowConfirmPassword(!showConfirmPassword);
    };

    const changePassword = (val: UserManagementChangePasswordType) => {
        onChangePassword(val);
        onClose();
    }

    const { data } = useDetailUserManagement({ user_id });

    const user = data?.data?.data?.user;

    return (
        <Modal open={isOpen} onClose={onClose}>
            <ModalHeader>Ganti Password</ModalHeader>
            <ModalBody className="pb-4">
                <Typography
                    variant="small"
                    color="gray"
                    className="mb-2"
                >
                    Ganti password untuk user {user?.full_name ?? '-'}
                </Typography>
                <form id="changePasswordForm" onSubmit={handleSubmit(changePassword)}>
                    <div className="grid grid-cols-12 items-center gap-2">
                        <div className="col-span-12 relative">
                            <TextInput
                                placeholder="Password"
                                type={showPassword ? "text" : "password"}
                                id="password"
                                {...register("new_password")}
                            />
                            <button
                                type="button"
                                onClick={toggleShowPassword}
                                className="absolute right-4 top-1/2 transform -translate-y-1/2 text-gray-500 focus:outline-none"
                            >
                                {showPassword ? <HiEye className="h-5 w-5" /> : <HiEyeOff className="h-5 w-5" />}
                            </button>
                        </div>
                        <div className="col-span-12 relative">
                            <TextInput
                                placeholder="Ulangi Password"
                                type={showConfirmPassword ? "text" : "password"}
                                id="new_password2"
                                {...register("new_password2", {
                                    validate: (val: string) => {
                                        if (watch("new_password") !== val) {
                                            return "Password Anda tidak cocok";
                                        }
                                    },
                                })}
                            />
                            <button
                                type="button"
                                onClick={toggleShowConfirmPassword}
                                className="absolute right-4 top-1/2 transform -translate-y-1/2 text-gray-500 focus:outline-none"
                            >
                                {showConfirmPassword ? <HiEye className="h-5 w-5" /> : <HiEyeOff className="h-5 w-5" />}
                            </button>
                        </div>
                        {errors.new_password2 && (
                            <div className="col-span-12">
                                <span className="text-red-500 text-sm">{errors.new_password2.message}</span>
                            </div>
                        )}
                    </div>
                </form>
            </ModalBody>
            <ModalFooter>
                <Button form="changePasswordForm" type="submit">Ganti</Button>
            </ModalFooter>
        </Modal>
    );
};

export default ModalChangePassword;
