import { useForm } from "react-hook-form";
import Modal from "components/Modal";
import { Select } from "flowbite-react";
import { Button } from "@material-tailwind/react";
import ModalHeader from "components/ModalHeader";
import ModalBody from "components/ModalBody";
import ModalFooter from "components/ModalFooter";
import { OPTIONS_MONTH } from "constants/options";
import getLastNYearList from "helpers/getLastNYearList";
import useStockRebalancing from "api/stock/useStockRebalancing";
import useToaster from "context/ToasterContext";
import PageLoader from "components/PageLoader";

interface ModalStockRebalancingType {
  month: number;
  year: number;
}

interface ModalStockRebalancingProps {
  open: boolean;
  onClose: () => void;
}

const ModalStockRebalancing = ({ open, onClose }: ModalStockRebalancingProps) => {
  const { open: openToaster } = useToaster();

  const {
    register,
    handleSubmit,
  } = useForm<ModalStockRebalancingType>({
    defaultValues: {
      month: Number(new Date().getMonth() + 1),
      year: Number(new Date().getFullYear()),
    },
  });

  const stockRebalancing = useStockRebalancing();

  const monthOptions = OPTIONS_MONTH;
  const yearOptions = getLastNYearList(4).map((i) => ({
    key: i,
    label: String(i),
  }));

  const submitForm = (val: ModalStockRebalancingType) => {
    stockRebalancing.mutateAsync({
      filter: {
        month: String(val.month),
        year: String(val.year),
      },
    }).then(res => {
      const isSuccess = res?.data?.success;
      if (isSuccess) {
        openToaster({
          title: "Berhasil",
          message: "Pembaruan data berhasil",
          variant: "success",
          autoClose: true,
        })
      } else {
        throw new Error("Pembaruan data gagal");
      }
    }).catch(err => {
      openToaster({
        title: "Gagal",
        message: err.message,
        variant: "error",
        autoClose: true,
      })
    }).finally(() => {
      onClose();
    })
  };

  return (
    <>
      {stockRebalancing.isLoading && <PageLoader />}
      <Modal open={open} onClose={onClose}>
        <form onSubmit={handleSubmit(submitForm)} noValidate>
          <ModalHeader>Pembaruan Data</ModalHeader>
          <ModalBody className="pb-4">
            <div className="grid grid-cols-12 items-center gap-2">
              <div className="col-span-6">
                <Select required {...register("month")}>
                  {monthOptions.map((item) => (
                    <option key={item.key} value={item.key}>
                      {item.label}
                    </option>
                  ))}
                </Select>
              </div>
              <div className="col-span-6">
                <Select required {...register("year")}>
                  {yearOptions.map((item) => (
                    <option key={item.key} value={item.key}>
                      {item.label}
                    </option>
                  ))}
                </Select>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button type="submit" className="w-full">
              Mulai Pembaruan Data
            </Button>
          </ModalFooter>
        </form>
      </Modal>
    </>
  );
};

export default ModalStockRebalancing;
