import axios, { AxiosResponse } from "axios";
import useAuthHeaders from "hooks/useAuthHeaders";
import useSignOut from 'react-auth-kit/hooks/useSignOut';
import { useQuery } from "react-query";
import { DashboardInformationResponse } from "types/dashboard-information";

interface GetDashboardInformationResponse extends AxiosResponse {
    data: DashboardInformationResponse;
}

const useDashboardDisease = () => {
    const signOut = useSignOut();
    const headers = useAuthHeaders();

    return useQuery<unknown, unknown, GetDashboardInformationResponse>({
        queryKey: ['get-dashboard-information'],
        queryFn: async () => {
            try {
                return await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/ticker`, { headers })
            } catch (err) {
                console.error(err);
                if (err?.response?.status === 401) signOut();
            }
        },
    });
}

export default useDashboardDisease;