import axios, { AxiosResponse } from "axios";
import useAuthHeaders from "hooks/useAuthHeaders";
import useSignOut from 'react-auth-kit/hooks/useSignOut';
import { UseQueryOptions, useQuery } from "react-query";
import { PatientTypeData } from "types/patient";

interface GetAllPatientResponse extends AxiosResponse {
  data: {
    data: {
      patient: PatientTypeData[];
      pagination: {
        current_page: number;
        total_page: number;
      }
    }
  }
}

const useAllPatient = (query: string, options?: UseQueryOptions<unknown, unknown, GetAllPatientResponse>) => {
  const signOut = useSignOut();
  const headers = useAuthHeaders();

  return useQuery<unknown, unknown, GetAllPatientResponse>({
    queryKey: ['get-all-patient', query],
    queryFn: async () => {
      try {
        return await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/patient?${query}`, { headers })
      } catch (err) {
        console.error(err);
        if (err?.response?.status === 401) signOut();
      }
    },
    ...options,
  })
}

export default useAllPatient;