import { Typography } from "@mui/material";
import ChartIncome from "./components/ChartIncome";
import Information from "./components/Information";
import Statistics from "./components/Statistics";
import TopDisease from "./components/TopDisease";

const Home = () => {
  return (
    <div className="space-y-8">
      <Typography variant="h4">
        Dashboard
      </Typography>
      <div className="grid grid-cols-12 gap-6">
        {/* left section */}
        <div className="col-span-12 space-y-10 lg:col-span-8">
          <div className="space-y-4">
            <Information />
          </div>

          <div>
            <Statistics />
          </div>

          <div>
            <ChartIncome />
          </div>
        </div>

        {/* right section */}
        <div className="col-span-12 space-y-6 lg:col-span-4">
          <TopDisease />
        </div>
      </div>

    </div>
  );
};

export default Home;
