import { useForm } from "react-hook-form";
import Modal from "components/Modal";
import { Button } from "@material-tailwind/react";
import ModalHeader from "components/ModalHeader";
import ModalBody from "components/ModalBody";
import ModalFooter from "components/ModalFooter";
import useToaster from "context/ToasterContext";
import { TextInput } from "flowbite-react";
import useChangePassword from "api/user/useChangePassword";
import { useState } from "react";
import { HiEye, HiEyeOff } from "react-icons/hi";


interface ModalChangePasswordType {
    new_password: String;
    new_password2: String;
}

interface ModalChangePasswordProps {
    open: boolean;
    onClose: () => void;
}

const ModalChangePassword = ({ open, onClose }: ModalChangePasswordProps) => {
    const { open: openToaster } = useToaster();
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setshowConfirmPassword] = useState(false);

    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
    } = useForm<ModalChangePasswordType>();

    const updatePassword = useChangePassword();

    const toggleShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const toggleShowConfirmPassword = () => {
        setshowConfirmPassword(!showConfirmPassword);
    };

    const submitForm = (val: ModalChangePasswordType) => {
        updatePassword.mutateAsync({
            new_password: val.new_password,
            new_password2: val.new_password2,
        }).then(res => {
            const isSuccess = res?.data?.success;
            if (isSuccess) {
                openToaster({
                    title: "Berhasil",
                    message: res?.data?.message,
                    variant: "success",
                    autoClose: true,
                })
            } else {
                throw new Error("Gagal mengubah password");
            }
        }).catch(err => {
            openToaster({
                title: "Gagal",
                message: err.message,
                variant: "error",
                autoClose: true,
            })
        }).finally(() => {
            onClose();
        });
    };

    return (
        <>
            <Modal open={open} onClose={onClose}>
                <form onSubmit={handleSubmit(submitForm)} noValidate>
                    <ModalHeader>Ganti Password</ModalHeader>
                    <ModalBody className="pb-4">
                        <div className="grid grid-cols-12 items-center gap-2">
                            <div className="col-span-12 relative">
                                <TextInput
                                    placeholder="Password"
                                    type={showPassword ? "text" : "password"}
                                    id="password"
                                    {...register("new_password")}
                                />
                                <button
                                    type="button"
                                    onClick={toggleShowPassword}
                                    className="absolute right-4 top-1/2 transform -translate-y-1/2 text-gray-500 focus:outline-none"
                                >
                                    {showPassword ? <HiEye className="h-5 w-5" /> : <HiEyeOff className="h-5 w-5" />}
                                </button>
                            </div>
                            <div className="col-span-12 relative">
                                <TextInput
                                    placeholder="Ulangi Password"
                                    type={showConfirmPassword ? "text" : "password"}
                                    id="new_password2"
                                    {...register("new_password2", {
                                        validate: (val: string) => {
                                            if (watch("new_password") !== val) {
                                                return "Password Anda tidak cocok";
                                            }
                                        },
                                    })}
                                />
                                <button
                                    type="button"
                                    onClick={toggleShowConfirmPassword}
                                    className="absolute right-4 top-1/2 transform -translate-y-1/2 text-gray-500 focus:outline-none"
                                >
                                    {showConfirmPassword ? <HiEye className="h-5 w-5" /> : <HiEyeOff className="h-5 w-5" />}
                                </button>
                            </div>
                            {errors.new_password2 && (
                                <div className="col-span-12">
                                    <span className="text-red-500 text-sm">{errors.new_password2.message}</span>
                                </div>
                            )}
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <Button type="submit" className="w-full">
                            Ganti Password
                        </Button>
                    </ModalFooter>
                </form>
            </Modal>
        </>
    );
};

export default ModalChangePassword;
