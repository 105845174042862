import "moment/locale/id";
import { Route, Routes } from "react-router-dom";
import Loadable from 'react-loadable';
import PageLoader from "components/PageLoader";

const LazyPatient = Loadable({
  loader: () => import("pages/Patient/index"),
  loading: () => <PageLoader />,
});

const LazyPatientForm = Loadable({
  loader: () => import("pages/PatientForm/index"),
  loading: () => <PageLoader />,
});

function PatientRoute() {
  return (
    <Routes>
      <Route path="/" element={<LazyPatient />} />
      <Route path="/new" element={<LazyPatientForm type="new" />} />
      <Route path="/edit/:patient_id" element={<LazyPatientForm type="edit" />} />
    </Routes>
  );
}

export default PatientRoute;
