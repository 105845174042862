import axios, { AxiosResponse } from "axios";
import useAuthHeaders from "hooks/useAuthHeaders";
import useSignOut from 'react-auth-kit/hooks/useSignOut';
import { useQuery } from "react-query";
import { ClinicDetailResponse } from "types/clinic-detail";

interface GetClinicResponse extends AxiosResponse {
  data: ClinicDetailResponse;
}

const useClinicDetail = ({ clinic_id }) => {
  const signOut = useSignOut();
  const headers = useAuthHeaders();

  return useQuery<unknown, unknown, GetClinicResponse>({
    queryKey: ['get-clinic-detail', clinic_id],
    queryFn: async () => {
      try {
        if (clinic_id === undefined) {
          return null;
        }

        return await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/clinic/${clinic_id}`, { headers })
      } catch (err) {
        console.error(err);
        if (err?.response?.status === 401) signOut();
      }
    },
  });
}

export default useClinicDetail;