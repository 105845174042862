import axios, { AxiosResponse } from "axios";
import useAuthHeaders from "hooks/useAuthHeaders";
import useSignOut from 'react-auth-kit/hooks/useSignOut';
import { useQuery } from "react-query";
import { DashboardPurchaseResponse } from "types/dashboard-purchase";

interface GetDashboardPurchaseResponse extends AxiosResponse {
  data: DashboardPurchaseResponse;
}

const useDashboardPurchase = () => {
  const signOut = useSignOut();
  const headers = useAuthHeaders();

  return useQuery<unknown, unknown, GetDashboardPurchaseResponse>({
    queryKey: ['get-dashboard-purchase'],
    queryFn: async () => {
      try {
        return await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/dashboard/purchase`, { headers })
      } catch (err) {
        console.error(err);
        if (err?.response?.status === 401) signOut();
      }
    },
  });
}

export default useDashboardPurchase;