import { UserData } from 'entities/model/user';
import useAuthUser from 'react-auth-kit/hooks/useAuthUser';

const useAuthHeaders = () => {
  const auth = useAuthUser<UserData>();

  const userId = auth?.userId;
  const clinicId = auth?.clinicId;
  const token = auth?.token;
  const clinicType = auth?.clinicType;

  return {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${token}`,
    'User-ID': userId,
    'Clinic-ID': clinicId,
    'Clinic-Type': clinicType
  }
}

export default useAuthHeaders;
