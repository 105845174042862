import axios from "axios"
import useSignOut from 'react-auth-kit/hooks/useSignOut';
import { useMutation } from "react-query"
import { PatientType } from "types/patient"
import useAuthHeaders from "hooks/useAuthHeaders";

const useAddPatient = () => {
  const signOut = useSignOut();
  const headers = useAuthHeaders();

  return useMutation(async (payload: PatientType) => {
    try {
      return await axios.post(`${process.env.REACT_APP_API_ENDPOINT}/patient`, JSON.stringify(payload), { headers });
    } catch (err) {
      console.error(err);
      if (err?.response?.status === 401) signOut();
    }
  })
}

export default useAddPatient;
