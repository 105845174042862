export const STATS = [
  {
    key: 'patient_visit',
    title: 'Pasien Terlayani',
    description: 'Jumlah pasien yang sudah terlayani pada hari ini',
    color: 'amber',
    url: '/medical-record',
  },
  {
    key: 'stock_minim',
    title: 'Stok Hampir Habis',
    description: 'Jumlah stok yang kurang dari batas minimal yang ditentukan',
    color: 'amber',
    url: '/stock',
  },
  {
    key: 'stock_almost_expired',
    title: 'Stok Hampir Expired',
    description: 'Jumlah stok akan kadaluarsa dalam kurang dari 30 hari kedepan',
    color: 'amber',
    url: '/stock',
  },
  {
    key: 'stock_expired',
    title: 'Stok Expired',
    description: 'Jumlah stok yang sudah kadaluarsa',
    color: 'amber',
    url: '/stock',
  },
]