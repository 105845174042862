import "moment/locale/id";
import { Route, Routes } from "react-router-dom";
import Loadable from 'react-loadable';
import PageLoader from "components/PageLoader";

const LazySubscription = Loadable({
  loader: () => import("pages/Subscription/index"),
  loading: () => <PageLoader />,
});

const LazySubscriptionPackage = Loadable({
    loader: () => import("pages/SubscriptionPackage/index"),
    loading: () => <PageLoader />,
  });

function SubscriptionRoute() {
  return (
    <Routes>
      <Route path="/" element={<LazySubscription />} />
      <Route path="/package" element={<LazySubscriptionPackage />} />
    </Routes>
  );
}

export default SubscriptionRoute;
