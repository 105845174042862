import axios from "axios";
import useSignOut from 'react-auth-kit/hooks/useSignOut';
import { useMutation } from "react-query";
import { ResetPasswordParam } from "types/reset-password";

const useReset = () => {
  const signOut = useSignOut();

  return useMutation(async (payload: ResetPasswordParam) => {
    try {
      return await axios.post(`${process.env.REACT_APP_API_ENDPOINT}/reset-password`, JSON.stringify(payload));
    } catch (err) {
      console.error(err);
      if (err?.response?.status === 401) signOut();
    }
  })
}

export default useReset;