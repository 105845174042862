import axios, { AxiosResponse } from "axios";
import useAuthHeaders from "hooks/useAuthHeaders";
import useSignOut from 'react-auth-kit/hooks/useSignOut';
import { useQuery } from "react-query";
import { PatientDetailResponse } from "types/patient-detail";

interface PatientDetailPayload {
  patient_id: string;
  skip?: boolean;
}
interface GetPatientDetailResponse extends AxiosResponse {
  data: PatientDetailResponse
}

const usePatientDetail = ({ patient_id, skip = false }: PatientDetailPayload) => {
  const signOut = useSignOut();
  const headers = useAuthHeaders();

  return useQuery<unknown, unknown, GetPatientDetailResponse>({
    queryKey: ['get-patient-detail', patient_id],
    queryFn: async () => {
      try {
        return await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/patient/${patient_id}`, { headers })
      } catch (err) {
        console.error(err);
        if (err?.response?.status === 401) signOut();
      }
    },
    enabled: !skip,
  })
};

export default usePatientDetail;
