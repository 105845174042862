import axios from "axios";
import useAuthHeaders from "hooks/useAuthHeaders";
import useSignOut from 'react-auth-kit/hooks/useSignOut';
import { useMutation } from "react-query";

interface User {
    email: string;
    password: string;
    phone_number: string;
    full_name: string;
    gender: string;
    birth_date: string;
    roles: string[];
    poly_clinic: string;
    type: string;
}

const useAddUserManagement = () => {
    const signOut = useSignOut();
    const headers = useAuthHeaders();

    return useMutation(async (payload: User) => {
        try {
            return await axios.post(`${process.env.REACT_APP_API_ENDPOINT}/user-management`, JSON.stringify(payload), { headers });
        } catch (err) {
            console.error(err);
            if (err?.response?.status === 401) signOut();
        }
    })
}

export default useAddUserManagement;
