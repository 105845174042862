import { Typography } from "@material-tailwind/react";
import { ReactNode } from "react";
import { PiCactusDuotone } from "react-icons/pi";

interface EmptyDataProps extends React.HTMLAttributes<HTMLDivElement> {
  children?: string | ReactNode;
}

const EmptyData = ({ children, className }: EmptyDataProps) => {
  const childrenIsString =
    typeof children === "string" || children === undefined;

  return (
    <div className={`flex h-full min-h-[48px] w-full items-center justify-center gap-2 bg-gray-50 ${className}`}>
      <PiCactusDuotone className="text-2xl text-gray-400" />
      {childrenIsString ? (
        <Typography className="!text-gray-400">
          {children || "Belum ada data"}
        </Typography>
      ) : (
        children
      )}
    </div>
  );
};

export default EmptyData;
