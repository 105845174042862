import { Button, Spinner, Typography } from "@material-tailwind/react";
import useAuthUser from 'react-auth-kit/hooks/useAuthUser';
import { useEditClinic, useDetailClinic } from "api/clinic";
import FormSection from "components/FormSection";
import { Label, Select, Textarea, TextInput } from "flowbite-react";
import { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { ClinicDetailResponse } from "types/clinic-detail";
import useToaster from "context/ToasterContext";
import { useTeritory } from "api/teritory";
import { HiCheckCircle } from "react-icons/hi";
import { UserData } from "entities/model/user";
import PageLoader from "components/PageLoader";
import AttachmentLogoClinic from "./clinic/AttachmentLogoClinic";
import { ClinicLogoAttachmentType } from "types/clinic-logo-attachment-type";
import { useUploadFiles } from "api/upload";
import { v4 as uuid } from "uuid";


type ClinicType = Partial<ClinicDetailResponse["data"]["clinic"]>;

const FormUserClinic = () => {
  const auth = useAuthUser<UserData>();
  const toaster = useToaster();

  const clinic_id = auth?.clinicId;
  const { data } = useDetailClinic({ clinic_id });
  const editClinic = useEditClinic();
  const clinicData = data?.data?.data?.clinic;

  const uploadFiles = useUploadFiles();

  const {
    register,
    setValue,
    getValues,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<ClinicType>();

  const [attLogo, setAttLogo] = useState<ClinicLogoAttachmentType>(null);

  const watchProvince = watch("province_code");
  const watchCity = watch("city_code");
  const watchDistrict = watch("district_code");
  const watchVillage = watch("village_code");

  const { data: teritoryData } = useTeritory({
    provinceCode: watchProvince,
    cityCode: watchCity,
    districtCode: watchDistrict,
  });

  const provinceOptions = useMemo(() => {
    return teritoryData.province.map((item) => ({
      key: item.province_code,
      label: item.province_name,
    }));
  }, [teritoryData.province]);

  const cityOptions = useMemo(() => {
    return teritoryData.city.map((item) => ({
      key: item.city_code,
      label: item.city_name,
    }));
  }, [teritoryData.city]);

  const districtOptions = useMemo(() => {
    return teritoryData.district.map((item) => ({
      key: item.district_code,
      label: item.district_name,
    }));
  }, [teritoryData.district]);

  const villageOptions = useMemo(() => {
    return teritoryData.village.map((item) => ({
      key: item.village_code,
      label: item.village_name,
      zip_code: item.zip_code,
    }));
  }, [teritoryData.village]);

  useEffect(() => {
    if (watchVillage) {
      setValue(
        "zip_code",
        villageOptions.find((i) => i.key === watchVillage)?.zip_code
      );
    }
  }, [setValue, villageOptions, watchVillage]);

  useEffect(() => {
    if (clinicData) {
      setValue("address", clinicData.address);
      setValue("operational_hour", clinicData.operational_hour);
      setValue("clinic_name", clinicData.clinic_name);
      setValue("phone_number", clinicData.phone_number);
      setValue("province_code", clinicData.province_code);
      setValue("city_code", clinicData.city_code);
      setValue("district_code", clinicData.district_code);
      setValue("village_code", clinicData.village_code);
      setValue("clinic_code", clinicData.clinic_code);
      setValue("license_number", clinicData.license_number);
      if (clinicData.image_url) {
        setAttLogo({
          id: uuid(),
          file: undefined,
          url: clinicData.image_url
        })
      }

    }
  }, [setValue, clinicData]);

  const handleUploadFiles = (item: ClinicLogoAttachmentType): Promise<ClinicLogoAttachmentType> => {
    return new Promise((resolve, reject) => {
      if (item.url) {
        resolve(item);
        return;
      }

      uploadFiles.mutateAsync([item.file])
        .then(res => {
          if (res.data?.data?.upload) {
            const updatedItem = {
              ...item,
              file: undefined,
              url: res.data.data.upload[0]
            };
            resolve(updatedItem);
          } else {
            reject(res.data.message || "Gagal mengupload file");
          }
        });
    });
  };

  const submitForm = async (val: ClinicType) => {
    try {
      let uploadedPhoto = null;
      if (attLogo) {
        uploadedPhoto = await handleUploadFiles(attLogo);
      }

      const updatedVal = {
        ...val,
        image_url: attLogo ? uploadedPhoto?.url || clinicData?.image_url : ""
      };

      if (!editClinic.isLoading) {
        editClinic
          .mutateAsync({ clinic_id, ...updatedVal })
          .then((response) => {
            const success = response?.status === 200;
            toaster.open({
              title: success ? "Berhasil" : "Gagal",
              message: success
                ? "Berhasil menyimpan perubahan"
                : "Gagal menyimpan perubahan. Silahkan ulangi lagi",
              variant: success ? "success" : "error",
              autoClose: true,
            });
          })
          .catch((err) => {
            toaster.open({
              title: "Gagal",
              message: err,
              variant: "error",
              autoClose: true,
            });
          });
      }
    } catch (error) {
      console.error("Submit error", error);
      toaster.open({
        title: "Gagal",
        message: error.message || "An error occurred",
        variant: "error",
        autoClose: true,
      });
    }
  };

  return (
    <>
      {editClinic.isLoading && (
        <PageLoader />
      )}
      <form noValidate onSubmit={handleSubmit(submitForm)}>
        <FormSection title="Klinik">
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
            <div className="space-y-4">
              <div>
                <AttachmentLogoClinic
                  title="Logo Klinik"
                  value={attLogo}
                  onChange={setAttLogo}
                />
              </div>
              <div>
                <Label htmlFor="clinic_code" value="Kode Reg Fasyankes" />
                <TextInput
                  type="text"
                  placeholder="12345"
                  color={Boolean(errors?.clinic_code) ? "failure" : "gray"}
                  helperText={errors?.clinic_code?.message}
                  {...register("clinic_code")}
                />
              </div>

              <div>
                <Label htmlFor="clinic_name" value="Nama Klinik" />
                <TextInput
                  type="text"
                  placeholder="Klinik Mitra Keluarga"
                  color={Boolean(errors?.clinic_name) ? "failure" : "gray"}
                  helperText={errors?.clinic_name?.message}
                  {...register("clinic_name", {
                    required: {
                      value: true,
                      message: "Wajib diisi",
                    },
                  })}
                />
              </div>

              <div>
                <Label htmlFor="license_number" value="Nomor Izin Klinik" />
                <TextInput
                  type="text"
                  color={Boolean(errors?.clinic_name) ? "failure" : "gray"}
                  helperText="Untuk praktik mandiri diisi SIP"
                  {...register("license_number", {
                    required: {
                      value: true,
                      message: "Wajib diisi",
                    },
                  })}
                />
              </div>

              <div>
                <div className="mb-2 block">
                  <Label htmlFor="address" value="Alamat Lengkap" />
                </div>
                <Textarea
                  rows={2}
                  required
                  placeholder="Alamat lengkap sesuai kartu identitas"
                  color={Boolean(errors?.address) ? "failure" : "gray"}
                  helperText={errors?.address?.message}
                  {...register("address", {
                    required: {
                      value: true,
                      message: "Wajib diisi",
                    },
                  })}
                />
              </div>

              <div>
                <Label htmlFor="province_code" value="Provinsi" />
                <Select
                  defaultValue=""
                  value={getValues("province_code") ?? ""}
                  required
                  color={Boolean(errors?.province_code) ? "failure" : "gray"}
                  helperText={errors?.province_code?.message}
                  {...register("province_code", {
                    required: {
                      value: true,
                      message: "Wajib diisi",
                    },
                  })}
                >
                  <option value="" disabled>
                    Pilih provinsi
                  </option>
                  {provinceOptions?.map((item) => (
                    <option key={item.key} value={item.key}>
                      {item.label}
                    </option>
                  ))}
                </Select>
              </div>

              <div>
                <Label htmlFor={"city_code"} value="Kota / Kabupaten" />
                <Select
                  defaultValue=""
                  value={getValues("city_code") ?? ""}
                  required
                  color={Boolean(errors?.["city_code"]) ? "failure" : "gray"}
                  helperText={errors?.["city_code"]?.message}
                  {...register("city_code", {
                    required: {
                      value: true,
                      message: "Wajib diisi",
                    },
                  })}
                >
                  <option value="" disabled>
                    Pilih kota atau kabupaten
                  </option>
                  {cityOptions?.map((item) => (
                    <option key={item.key} value={item.key}>
                      {item.label}
                    </option>
                  ))}
                </Select>
              </div>

              <div>
                <Label htmlFor={"district_code"} value="Kecamatan" />
                <Select
                  defaultValue=""
                  value={getValues("district_code") ?? ""}
                  required
                  color={Boolean(errors?.["district_code"]) ? "failure" : "gray"}
                  helperText={errors?.["district_code"]?.message}
                  {...register("district_code", {
                    required: {
                      value: true,
                      message: "Wajib diisi",
                    },
                  })}
                >
                  <option value="" disabled>
                    Pilih kecamatan
                  </option>
                  {districtOptions?.map((item) => (
                    <option key={item.key} value={item.key}>
                      {item.label}
                    </option>
                  ))}
                </Select>
              </div>

              <div>
                <Label htmlFor={"village_code"} value="Kelurahan / Desa" />
                <Select
                  defaultValue=""
                  value={getValues('village_code') ?? ""}
                  required
                  color={Boolean(errors?.["village_code"]) ? "failure" : "gray"}
                  helperText={errors?.["village_code"]?.message}
                  {...register("village_code", {
                    required: {
                      value: true,
                      message: "Wajib diisi",
                    },
                  })}
                >
                  <option value="" disabled>
                    Pilih kelurahan atau desa
                  </option>
                  {villageOptions?.map((item) => (
                    <option key={item.key} value={item.key}>
                      {item.label}
                    </option>
                  ))}
                </Select>
              </div>

              <div>
                <Label htmlFor="zip_code" value="Kode Pos" />
                <TextInput
                  type="text"
                  placeholder="12345"
                  color={Boolean(errors?.zip_code) ? "failure" : "gray"}
                  helperText={errors?.zip_code?.message}
                  {...register("zip_code")}
                />
              </div>

              <div>
                <Label htmlFor="operational_hour" value="Jam Operasional Klinik" />
                <TextInput
                  type="text"
                  placeholder="Buka tiap hari jam 18.00 - 21.00"
                  color={Boolean(errors?.operational_hour) ? "failure" : "gray"}
                  helperText={errors?.operational_hour?.message}
                  {...register("operational_hour", {
                    required: {
                      value: true,
                      message: "Wajib diisi",
                    },
                  })}
                />
              </div>

              <div>
                <Label htmlFor="operational_hour" value="Nomor Telepon" />
                <TextInput
                  type="text"
                  placeholder="(021) XXX-XXX"
                  color={Boolean(errors?.phone_number) ? "failure" : "gray"}
                  helperText={errors?.phone_number?.message}
                  {...register("phone_number", {
                    required: {
                      value: true,
                      message: "Wajib diisi",
                    },
                  })}
                />
              </div>

              <div className="flex justify-end">
                <Button type="submit">
                  {editClinic.isLoading ? (
                    <Spinner className="mx-auto block" />
                  ) : (
                    "Simpan"
                  )}
                </Button>
              </div>
            </div>
            <div className="space-y-4">
              <div>
                <Label htmlFor="active_until" value="Masa Aktif Klinik" />
                <Typography className="font-bold text-lg text-green-500">{clinicData?.active_until}</Typography>
              </div>
              <div className="bg-gray-100 p-4 rounded-md">
                <div className="flex gap-4 justify-between items-center w-full">
                  <div>
                    <Typography className="font-bold text-sm text-gray-600">
                      Status SATUSEHAT
                    </Typography>
                    {Number(clinicData?.satusehat_status) === 1 ? (
                      <div className="flex gap-2">
                        <HiCheckCircle className="text-green-500" size={24} />
                        <Typography className="font-bold text-lg text-green-500">Terhubung</Typography>
                      </div>
                    ) : (
                      <Typography className="font-bold text-lg text-red-500">Belum Terhubung</Typography>
                    )}
                  </div>
                  <img src="/logo_satusehat.png" alt="Satu Sehat" width={32} height={32} />
                </div>
              </div>

              <div className="bg-gray-100 p-4 rounded-md">
                <div className="space-y-6">
                  <div>
                    <Typography className="font-bold text-md mb-2">
                      Bagaimana agar terhubung ke SATUSEHAT?
                    </Typography>
                    <ol className="list-decimal list-inside space-y-1" style={{}}>
                      <li>Daftarkan fasyankes Anda ke platform Mitramedis dengan masukkan <b>Kode Reg Fasyankes</b> ke kolom di sebelah kiri, lalu klik <b>Simpan</b></li>
                      <li>Tim kami akan segera melakukan pengecekan dan menghubungkan fasyankes Anda ke platform <b>SATUSEHAT</b></li>
                      <li>Lakukan pengecekan secara berkala untuk mengetahui update status pendaftaran fasyankes Anda</li>
                    </ol>
                  </div>

                  <div>
                    <Typography className="font-bold text-md mb-2">
                      Bagaimana cara mengetahui kode fasyankes saya?
                    </Typography>
                    <ol className="list-decimal list-inside space-y-1">
                      <li>Login & Cek Kode Reg Fasyankes Anda <a className=' text-sky-500 font-bold' href='https://registrasifasyankes.kemkes.go.id/' target='_blank' rel='noopener noreferrer'>di sini</a></li>
                      <li>Jika kode faskes (Kode Reg Fasyankes) Anda tidak ditemukan atau Anda belum mempunyai kode faskes, Anda WAJIB mendaftarkan Fasyankes Anda <a className=' text-sky-500 font-bold' href='https://registrasifasyankes.kemkes.go.id/register/' target="_blank" rel='noopener noreferrer'>di sini</a></li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </FormSection>
      </form>
    </>
  );
};

export default FormUserClinic;
