import axios from "axios";
import useAuthHeaders from "hooks/useAuthHeaders";
import useSignOut from 'react-auth-kit/hooks/useSignOut';
import { useMutation } from "react-query";

interface DeletePatientPayload {
  patient_id: string;
}

const useDeletePatient = () => {
  const signOut = useSignOut();
  const headers = useAuthHeaders();

  return useMutation(async ({ patient_id }: DeletePatientPayload) => {
    try {
      return await axios.delete(`${process.env.REACT_APP_API_ENDPOINT}/patient/${patient_id}`, { headers });
    } catch (err) {
      console.error(err);
      if (err?.response?.status === 401) signOut();
    }
  })
}

export default useDeletePatient;
