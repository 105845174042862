import React, { useState } from 'react';
import PageHeading from 'components/PageHeading';
import { Card } from '@material-tailwind/react';
import FormUser from './components/FormUser';
import FormClinic from './components/FormClinic';
import Tabs from './components/Tabs';
import PageManagementUser from './components/PageManagementUser';
import useAppContext from 'context/AppContext';

const TABS = [
  { key: 'user', title: ' Profil', content: <FormUser /> },
  { key: 'clinic', title: 'Klinik', content: <FormClinic /> },
  { key: 'management_user', title: 'User', content: <PageManagementUser /> }
];

const Settings = () => {
  const [activeTab, setActiveTab] = useState('user');
  const { userDetail } = useAppContext();

  const filteredTabs = userDetail?.is_admin === '0'
    ? TABS.filter(tab => tab.key === 'user')
    : TABS;

  return (
    <>
      <PageHeading title="Pengaturan" breadcrumbs={[{ text: 'Pengaturan' }]} />
      <div className="space-y-4">
        <Tabs
          tabs={filteredTabs}
          activeTab={activeTab}
          onTabChange={(tabKey) => setActiveTab(tabKey)}
        />
        <Card className="min-h-[500px] p-4">
          {TABS.find((t) => t.key === activeTab).content}
        </Card>
      </div>
    </>
  );
};

export default Settings;
