import "moment/locale/id";
import { Route, Routes } from "react-router-dom";
import Loadable from 'react-loadable';
import PageLoader from "components/PageLoader";

const LazyPaymentLandingPage = Loadable({
  loader: () => import("pages/Payment/index"),
  loading: () => <PageLoader />,
});

function PaymentRoute() {
  return (
    <Routes>
      <Route path="/finish" element={<LazyPaymentLandingPage />} />
    </Routes>
  );
}

export default PaymentRoute;
