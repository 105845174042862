import axios, { AxiosResponse } from "axios";
import useAuthHeaders from "hooks/useAuthHeaders";
import useSignOut from 'react-auth-kit/hooks/useSignOut';
import { useQuery } from "react-query";
import { DashboardIncomeResponse } from "types/dashboard-income";

interface GetDashboardIncomeResponse extends AxiosResponse {
  data: DashboardIncomeResponse;
}

const useDashboardIncome = () => {
  const signOut = useSignOut();
  const headers = useAuthHeaders();

  return useQuery<unknown, unknown, GetDashboardIncomeResponse>({
    queryKey: ['get-dashboard-income'],
    queryFn: async () => {
      try {
        return await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/dashboard/income`, { headers })
      } catch (err) {
        console.error(err);
        if (err?.response?.status === 401) signOut();
      }
    },
  });
}

export default useDashboardIncome;