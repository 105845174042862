import "moment/locale/id";
import { Route, Routes } from "react-router-dom";
import Loadable from 'react-loadable';
import PageLoader from "components/PageLoader";

const LazyDocumentPatient = Loadable({
  loader: () => import("pages/DocumentPatient/index"),
  loading: () => <PageLoader />,
});

const LazyDocumentMedicalResume = Loadable({
  loader: () => import("pages/DocumentMedicalResume/index"),
  loading: () => <PageLoader />,
});

const LazyDocumentMedicalReferral = Loadable({
  loader: () => import("pages/DocumentMedicalReferral/index"),
  loading: () => <PageLoader />,
});

const LazyDocumentSickLetter = Loadable({
  loader: () => import("pages/DocumentSickLetter/index"),
  loading: () => <PageLoader />,
});

const LazyDocumentHealthLetter = Loadable({
  loader: () => import("pages/DocumentHealthLetter/index"),
  loading: () => <PageLoader />,
});

const LazyDocumentBilling = Loadable({
  loader: () => import("pages/DocumentBilling/index"),
  loading: () => <PageLoader />,
});

function DocumentRoute() {
  return (
    <Routes>
      <Route path="/patient" element={<LazyDocumentPatient />} />
      <Route path="/medical-resume" element={<LazyDocumentMedicalResume />} />
      <Route path="/medical-referral" element={<LazyDocumentMedicalReferral />} />
      <Route path="/sick-letter" element={<LazyDocumentSickLetter />} />
      <Route path="/health-letter" element={<LazyDocumentHealthLetter />} />
      <Route path="/billing" element={<LazyDocumentBilling />} />
    </Routes>
  );
}

export default DocumentRoute;
