import axios from "axios";
import useAuthHeaders from "hooks/useAuthHeaders";
import useSignOut from 'react-auth-kit/hooks/useSignOut';
import { useMutation } from "react-query";

interface UserManagementUpdateType {
    user_id: string;
}

const useUpdateUserManagement = () => {
    const signOut = useSignOut();
    const headers = useAuthHeaders();

    return useMutation(async ({ user_id, ...payload }: UserManagementUpdateType) => {
        try {
            return await axios.put(`${process.env.REACT_APP_API_ENDPOINT}/user-management/${user_id}`, JSON.stringify(payload), { headers });
        } catch (err) {
            console.error(err);
            if (err?.response?.status === 401) signOut();
        }
    })
}

export default useUpdateUserManagement;
