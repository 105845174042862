import axios from "axios";
import useAuthHeaders from "hooks/useAuthHeaders";
import useSignOut from 'react-auth-kit/hooks/useSignOut';
import { useMutation } from "react-query";

interface UserEditType {
  user_id: string
}

const useEditUser = () => {
  const signOut = useSignOut();
  const headers = useAuthHeaders();

  return useMutation(async ({ user_id, ...payload }: UserEditType) => {
    try {
      return await axios.put(`${process.env.REACT_APP_API_ENDPOINT}/user/${user_id}`, JSON.stringify(payload), { headers });
    } catch (err) {
      console.error(err);
      if (err?.response?.status === 401) signOut();
    }
  })
}

export default useEditUser;
