import axios from "axios";
import useAuthHeaders from "hooks/useAuthHeaders";
import useSignOut from 'react-auth-kit/hooks/useSignOut';
import { useMutation } from "react-query";

interface ClinicEditType {
  clinic_id: string
}

const useEditClinic = () => {
  const signOut = useSignOut();
  const headers = useAuthHeaders();

  return useMutation(async ({ clinic_id, ...payload }: ClinicEditType) => {
    try {
      return await axios.put(`${process.env.REACT_APP_API_ENDPOINT}/clinic/${clinic_id}`, JSON.stringify(payload), { headers });
    } catch (err) {
      console.error(err);
      if (err?.response?.status === 401) signOut();
    }
  })
}

export default useEditClinic;
