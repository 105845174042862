import React from 'react';

function SidebarOverlay({ isSidebarOpen, toggleSidebar }) {
  return isSidebarOpen ? (
    <div
      onClick={toggleSidebar}
      className="fixed inset-0 z-10 bg-black bg-opacity-20 lg:hidden"
      style={{
        backdropFilter: 'blur(14px)',
        WebkitBackdropFilter: 'blur(14px)',
      }}
    />
  ) : null;
}

export default SidebarOverlay;
