import axios from "axios";
import useAuthHeaders from "hooks/useAuthHeaders";
import useSignOut from 'react-auth-kit/hooks/useSignOut';
import { useMutation } from "react-query";

interface DeleteUserManagementPayload {
    user_id: string;
}

const useDeleteUserManagement = () => {
    const signOut = useSignOut();
    const headers = useAuthHeaders();

    return useMutation(async ({ user_id }: DeleteUserManagementPayload) => {
        try {
            return await axios.delete(`${process.env.REACT_APP_API_ENDPOINT}/user-management/${user_id}`, { headers });
        } catch (err) {
            console.error(err);
            if (err?.response?.status === 401) signOut();
        }
    })
}

export default useDeleteUserManagement;
