import "moment/locale/id";
import { Route, Routes } from "react-router-dom";
import Loadable from 'react-loadable';
import PageLoader from "components/PageLoader";

const LazyMedicalTreatment = Loadable({
  loader: () => import("pages/MedicalTreatment/index"),
  loading: () => <PageLoader />,
});

function MedicalTreatmentRoute() {
  return (
    <Routes>
      <Route path="/" element={<LazyMedicalTreatment />} />
    </Routes>
  );
}

export default MedicalTreatmentRoute;
