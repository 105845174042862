import { Button, Typography } from "@material-tailwind/react";

const HelpCenter = () => {
    const handleWhatsAppClick = () => {
        window.open('https://wa.me/6282245545552', '_blank');
    };

    return (
        <div className="flex flex-col md:flex-row items-center justify-center">
            <div className="md:w-3/5 md:mr-10 md:pr-8 mb-8 md:mb-0">
                <Typography variant="h3" className="text-3xl font-extrabold tracking-tight text-gray-900 dark:text-white md:text-4xl">
                    Pusat Bantuan
                </Typography>
                <Typography variant="paragraph" className="text-lg mt-4">
                    Kami di sini untuk membantu Anda dengan segala
                    pertanyaan, masalah, atau umpan balik yang Anda miliki. Kami berkomitmen untuk memberikan layanan
                    pelanggan yang berkualitas dan responsif.
                </Typography>
                <Typography variant="paragraph" className="text-lg mt-4">
                    Butuh bantuan lebih lanjut? Tim dukungan kami siap membantu! Hubungi kami melalui tombol dibawah ini atau temukan cara lain untuk menghubungi kami di bagian informasi kontak.
                </Typography>
                <Button className="mt-8" onClick={handleWhatsAppClick}>
                    Hubungi Kami
                </Button>
            </div>
            <img src="/help_control.svg" alt="Help Center" className="w-full md:w-2/5" />
        </div>
    );
};

export default HelpCenter;
