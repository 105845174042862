import "moment/locale/id";
import { Route, Routes } from "react-router-dom";
import Loadable from 'react-loadable';
import PageLoader from "components/PageLoader";

const LazyPharmacy = Loadable({
    loader: () => import("pages/Pharmacy/index"),
    loading: () => <PageLoader />,
});

const LazyPharmacyDetail = Loadable({
    loader: () => import("pages/PharmacyDetail/index"),
    loading: () => <PageLoader />,
});

function PharmacyRoute() {
    return (
        <Routes>
            <Route path="/" element={<LazyPharmacy />} />
            <Route path="/detail/:pharmacy_id/:status_id" element={<LazyPharmacyDetail />} />
        </Routes>
    );
}

export default PharmacyRoute;
