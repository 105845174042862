import axios, { AxiosResponse } from "axios";
import { useQuery } from "react-query";

type UseActivationProps = {
  filter?: Record<string, string>
}

const useActivate = (payload?: UseActivationProps) => {
  return {
    ...useQuery<unknown, unknown, AxiosResponse>({
      queryKey: ['get-activation', payload?.filter],
      queryFn: async () => {
        const query = new URLSearchParams({ ...payload?.filter });

        return await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/activation?${query}`)
      },
    }),
  }
}

export default useActivate;
