import axios, { AxiosResponse } from "axios";
import useAuthHeaders from "hooks/useAuthHeaders";
import useSignOut from 'react-auth-kit/hooks/useSignOut';
import { useQuery } from "react-query";
import { DashboardSummaryResponse } from "types/dashboard-summary";

interface GetDashboardSummaryResponse extends AxiosResponse {
  data: DashboardSummaryResponse;
}

const useDashboardSummary = () => {
  const signOut = useSignOut();
  const headers = useAuthHeaders();

  return useQuery<unknown, unknown, GetDashboardSummaryResponse>({
    queryKey: ['get-dashboard-summary'],
    queryFn: async () => {
      try {
        return await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/dashboard/summary`, { headers })
      } catch (err) {
        console.error(err);
        if (err?.response?.status === 401) signOut();
      }
    },
  });
}

export default useDashboardSummary;