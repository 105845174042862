import axios from "axios";
import useAuthHeaders from "hooks/useAuthHeaders";
import useSignOut from 'react-auth-kit/hooks/useSignOut';
import { useMutation } from "react-query";

interface UserEditPasswordType {
    user_id: string,
    new_password: String,
    new_password2: String
}

const useChangePasswordUserManagement = () => {
    const signOut = useSignOut();
    const headers = useAuthHeaders();

    return useMutation(async ({ ...payload }: UserEditPasswordType) => {
        try {
            return await axios.put(`${process.env.REACT_APP_API_ENDPOINT}/user-management/update-password`, JSON.stringify(payload), { headers });
        } catch (err) {
            console.error(err);
            if (err?.response?.status === 401) signOut();
        }
    })
}

export default useChangePasswordUserManagement;
